import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@heroui/react";
import { useForm } from "@mantine/form";
import { api } from "@/lib/api-client";
import { LimeInputOtp } from "@/Components/NextBase/LimeInputOtp";
import { useEffect, useRef } from "react";

export type POSPinSetupForm = {
  PIN: string;
};

export const POSPinSetupDialog = ({
  userId,
  handleCreatedPin,
  handleAuthorizeModalClose,
}: {
  userId: number;
  handleCreatedPin: () => void;
  handleAuthorizeModalClose: (open: boolean) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const form = useForm<POSPinSetupForm>({
    initialValues: {
      PIN: "",
    },
    validate: {
      PIN: (value) => (value ? null : t`Obvezno polje`),
    },
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const { mutateAsync: postUserPIN, isPending: isPostingUserPIN } =
    api.user.usePostUserPIN();

  const handleSubmit = async (data: POSPinSetupForm) => {
    console.log(data);

    const response = await postUserPIN({
      PIN: data.PIN,
      userId,
    });

    console.log(response);

    handleCreatedPin();
  };

  return (
    <Modal isOpen={true} onOpenChange={handleAuthorizeModalClose}>
      <ModalContent>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <ModalHeader>
            <Trans>Ustvarjanje PIN kode</Trans>
          </ModalHeader>

          <ModalBody className="flex flex-col items-center justify-center gap-8">
            <div className="flex flex-col items-center gap-2">
              <p className="font-semibold">
                <Trans>PIN Koda</Trans>
              </p>

              <LimeInputOtp
                length={4}
                {...form.getInputProps("PIN")}
                type="password"
                variant="bordered"
                ref={inputRef}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button isLoading={isPostingUserPIN} type="submit">
              <Trans>Ustvari PIN kodo</Trans>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
