import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { convertMinutesToHHmm } from "@/utils/date-time-utils";
import {
  ActionIcon,
  Avatar,
  Drawer,
  Flex,
  Skeleton,
  Text,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  RiCheckFill,
  RiContactsBook2Line,
  RiListSettingsFill,
  RiTimeLine,
  RiUserFill,
} from "react-icons/ri";
import { useAnalyticsStore } from "../../stores/useAnalyticsStore";
import { AnalyticsCarousel } from "../AnalyticsCarousel";
import classes from "./TabUsers.module.css";
import { AnalyticsTable } from "./Tables/AnalyticsTable";
import { UsersBarGraphWorkingHours } from "./UsersBarGraphWorkingHours";
import { UsersLineGraphAppointments } from "./UsersLineGraphAppointments";
import { UsersLineGraphIncome } from "./UsersLineGraphIncome";

import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../../../shared/utils/utils";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";

const colors = ["#224d9c", "#d9b944", "#c14047", "#e1b4bf", "#229c82"];

export const TabUsers = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);

  // page state
  const { selectedLocations, dateRange } = useAnalyticsStore((state) => state);
  const { permissions } = useUserPermissionsStore((state) => state);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  // data fetching
  // users
  const { data: userList } = api.user.useUserList({
    locationIds: selectedLocations,
    perPage: 100,
  });
  // charts
  const { data: userActivity, isFetching: isUserActivityLoading } =
    api.analytics.useUserActivityPerDate(
      {
        userIds: selectedUsers,
        startDate: dayjs(dateRange[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange[1]).format("YYYY-MM-DD"),
        locationIds: selectedLocations,
      },
      selectedUsers.length > 0 && selectedLocations.length > 0,
    );
  //tables
  // table 1
  const { data: userGeneral, isFetching: isGeneralLoading } =
    api.analytics.useAnalyticsUserGeneral(
      {
        startDate: dayjs(dateRange[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange[1]).format("YYYY-MM-DD"),
        userIds: selectedUsers,
        locationIds: selectedLocations,
      },
      selectedUsers.length > 0 && selectedLocations.length > 0,
    );
  // table 2
  const { data: userWorkTime, isFetching: isWorkTimeLoading } =
    api.analytics.useAnalyticsUserWorkTime(
      {
        startDate: dayjs(dateRange[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange[1]).format("YYYY-MM-DD"),
        userIds: selectedUsers,
        locationIds: selectedLocations,
      },
      selectedUsers.length > 0 && selectedLocations.length > 0,
    );
  // table 3
  const { data: userChangeLog, isFetching: isChangeLogLoading } =
    api.analytics.useAnalyticsChangeLog(
      {
        userIds: selectedUsers,
        startDate: dayjs(dateRange[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange[1]).format("YYYY-MM-DD"),
        locationIds: selectedLocations,
      },
      selectedUsers.length > 0 && selectedLocations.length > 0,
    );

  // preselect all users on user load
  useEffect(() => {
    if (selectedUsers.length === 0) {
      setSelectedUsers(userList?.users.map((u) => u.userId) || []);
    }
  }, [userList, setSelectedUsers]);

  // process user data
  const usersWithColors = useMemo(() => {
    return (
      userList?.users.map((u, i) => ({
        ...u,
        color:
          colors[i] ||
          "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0"),
      })) || []
    );
  }, [userList?.users]);

  // initialize charts
  const charts = userActivity
    ? [
        ...(permissions.analytics_finance
          ? [
              {
                label: t`Linearni prikaz prihodkov`,
                chart: (
                  <UsersLineGraphIncome
                    users={usersWithColors}
                    data={userActivity.days}
                    currencySymbol={returnCurrencySymbol({
                      currency: userGeneral?.currency || "EUR",
                    })}
                  />
                ),
              },
            ]
          : []),
        {
          label: t`Linearni prikaz števila terminov`,
          chart: (
            <UsersLineGraphAppointments
              data={userActivity.days}
              users={usersWithColors}
            ></UsersLineGraphAppointments>
          ),
        },
        {
          label: t`Stolpični prikaz dela`,
          chart: (
            <UsersBarGraphWorkingHours
              data={userActivity.days}
              users={usersWithColors}
            ></UsersBarGraphWorkingHours>
          ),
        },
      ]
    : [];

  console.log(charts);

  return (
    <div className={classes.wrapper}>
      <section className={classes.topSection}>
        {isDesktop && (
          <div className={classes.userSelect}>
            {userList ? (
              <UserPicker
                userList={usersWithColors}
                selectedUsers={selectedUsers}
                setSeletedUsers={setSelectedUsers}
              ></UserPicker>
            ) : (
              <Skeleton h="100%" w="100%"></Skeleton>
            )}

            <Text
              variant={TextVariant.CaptionEmphasized}
              c={Color.SecondaryText}
              className={classes.userTitle}
              p={12}
            >
              <Trans>Prikaz podatkov zaposlenih</Trans>
            </Text>
          </div>
        )}
        <div className={classes.charts}>
          <AnalyticsCarousel
            charts={charts}
            isLoaded={
              !!userActivity &&
              selectedUsers.length > 0 &&
              selectedLocations.length > 0
            }
            isLoading={isUserActivityLoading}
            indicatorPosition="under"
            gap={10}
            mobileActionButton={
              <ActionIcon
                onClick={openModal}
                bg={Color.Background}
                p={4}
                style={{ boxSizing: "content-box" }}
              >
                <RiListSettingsFill size={24}></RiListSettingsFill>
              </ActionIcon>
            }
          ></AnalyticsCarousel>
        </div>
      </section>
      <section className={classes.tables}>
        <AnalyticsTable
          title={t`Splošna analitika`}
          icon={<RiUserFill></RiUserFill>}
          numberOfSelected={selectedUsers.length}
          isLoading={isGeneralLoading}
          content={[
            ...(permissions.analytics_finance
              ? [
                  {
                    title: t`Prihodki`,
                    colorTitle: "PrimaryText" as keyof typeof Color,
                    colorValue: "PrimaryText" as keyof typeof Color,
                    value:
                      userGeneral && selectedUsers.length > 0
                        ? formatCurrency({
                            amount: userGeneral.totalIncome || 0,
                            currency: userGeneral.currency || "EUR",
                            locale: preferredLanguage.userPreferredLanguage,
                          })
                        : "",
                  },
                  {
                    title: t`Povp. prihodki na termin`,
                    colorTitle: "SecondaryText" as keyof typeof Color,
                    colorValue: "PrimaryText" as keyof typeof Color,
                    value:
                      userGeneral && selectedUsers.length > 0
                        ? formatCurrency({
                            amount:
                              userGeneral.averageIncomePerAppointment || 0,
                            currency: userGeneral.currency || "EUR",
                            locale: preferredLanguage.userPreferredLanguage,
                          })
                        : "",
                  },
                ]
              : []),
            {
              title: t`Št. strank`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userGeneral && selectedUsers.length > 0
                  ? `${userGeneral.numberOfCustomers}`
                  : "",
            },
            {
              title: t`Št. strank preko spleta`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userGeneral && selectedUsers.length > 0
                  ? `${userGeneral.numberOfCustomersFromForm}`
                  : "",
              valuePercentage:
                userGeneral && selectedUsers.length > 0
                  ? `${(
                      (userGeneral.numberOfCustomersFromForm /
                        userGeneral.numberOfCustomers) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Št. terminov`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userGeneral && selectedUsers.length > 0
                  ? `${userGeneral.numberOfAppointments}`
                  : "",
            },
            {
              title: t`Št. terminov preko spleta`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userGeneral && selectedUsers.length > 0
                  ? `${userGeneral.numberOfAppointmentsFromForm}`
                  : "",
              valuePercentage:
                userGeneral && selectedUsers.length > 0
                  ? `${(
                      (userGeneral.numberOfAppointmentsFromForm /
                        userGeneral.numberOfAppointments) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Izgubljeni prihodki zaradi neprihodov`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userGeneral && selectedUsers.length > 0
                  ? formatCurrency({
                      amount: userGeneral.revenueLostNoShow || 0,
                      currency: userGeneral.currency || "EUR",
                      locale: preferredLanguage.userPreferredLanguage,
                    })
                  : "",

              valuePercentage:
                userGeneral && selectedUsers.length > 0
                  ? `${(
                      (userGeneral.revenueLostNoShow /
                        (userGeneral.totalIncome +
                          userGeneral.revenueLostNoShow)) *
                      100
                    ).toFixed(2)} %`
                  : "",

              colorValuePercentage: "Neutral",
            },
          ]}
        ></AnalyticsTable>
        <AnalyticsTable
          title={t`Podatki o delu`}
          icon={<RiTimeLine></RiTimeLine>}
          numberOfSelected={selectedUsers.length}
          isLoading={isWorkTimeLoading}
          content={[
            {
              title: t`Skupaj delovne ure`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.workTime)} h`
                  : "",
            },
            {
              title: t`Dejanski čas izvajanja storitev`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.appointmentsTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.appointmentsTime /
                        (userWorkTime.workTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Malica`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.lunchTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.lunchTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Dopusti`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.vacationTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.vacationTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Bolniške`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.sickLeaveTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.sickLeaveTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Pavze`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.breakTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.breakTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Ostalo`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.otherTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.otherTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
            },
            {
              title: t`Walkini`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userWorkTime && selectedUsers.length > 0
                  ? `${convertMinutesToHHmm(userWorkTime.walkinTime)} h`
                  : "",
              valuePercentage:
                userWorkTime && selectedUsers.length > 0
                  ? `${(
                      (userWorkTime.walkinTime /
                        (userWorkTime.totalShiftTime || 1)) *
                      100
                    ).toFixed(2)} %`
                  : "",
              colorValuePercentage: "Neutral",
              hide: !userWorkTime?.walkinTime || userWorkTime?.walkinTime <= 0,
            },
          ]}
        ></AnalyticsTable>
        <AnalyticsTable
          title={t`Podatki o terminih`}
          icon={<RiContactsBook2Line></RiContactsBook2Line>}
          numberOfSelected={selectedUsers.length}
          isLoading={isChangeLogLoading}
          content={[
            {
              title: t`Št. prenaročanj`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${userChangeLog.countReschedule}`
                  : "",
            },
            {
              title: t`Št. prenaročanj - koledar`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${
                      userChangeLog.countReschedule -
                      userChangeLog.countRescheduleForm
                    }`
                  : "",
              colorValuePercentage: "Neutral",
              valuePercentage:
                userChangeLog && selectedUsers.length > 0
                  ? `${(
                      ((userChangeLog.countReschedule -
                        userChangeLog.countRescheduleForm) /
                        userChangeLog.countReschedule || 1) * 100
                    ).toFixed(2)} %`
                  : "",
            },
            {
              title: t`Št. prenaročanj - splet`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${userChangeLog.countRescheduleForm}`
                  : "",
              colorValuePercentage: "Neutral",
              valuePercentage:
                userChangeLog && selectedUsers.length > 0
                  ? `${(
                      (userChangeLog.countRescheduleForm /
                        userChangeLog.countReschedule || 1) * 100
                    ).toFixed(2)} %`
                  : "",
            },
            {
              title: t`Št. odpovedi`,
              colorTitle: "PrimaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${userChangeLog.countCancel}`
                  : "",
            },
            {
              title: t`Št. odpovedi - koledar`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${
                      userChangeLog.countCancel - userChangeLog.countCancelForm
                    }`
                  : "",
              colorValuePercentage: "Neutral",
              valuePercentage:
                userChangeLog && selectedUsers.length > 0
                  ? `${(
                      ((userChangeLog.countCancel -
                        userChangeLog.countCancelForm) /
                        userChangeLog.countCancel || 1) * 100
                    ).toFixed(2)} %`
                  : "",
            },
            {
              title: t`Št. odpovedi - splet`,
              colorTitle: "SecondaryText",
              colorValue: "PrimaryText",
              value:
                userChangeLog && selectedUsers.length > 0
                  ? `${userChangeLog.countCancelForm}`
                  : "",
              colorValuePercentage: "Neutral",
              valuePercentage:
                userChangeLog && selectedUsers.length > 0
                  ? `${(
                      (userChangeLog.countCancelForm /
                        userChangeLog.countCancel || 1) * 100
                    ).toFixed(2)} %`
                  : "",
            },
          ]}
        ></AnalyticsTable>
      </section>

      {!isDesktop && (
        <Drawer
          title={t`Prikaz podatkov zaposlenih`}
          position={"bottom"}
          opened={modalOpened}
          onClose={closeModal}
          overlayProps={{ backgroundOpacity: 0.3, blur: 1 }}
          className="analytics-users-drawer"
        >
          <div className={classes.drawerContent}>
            <UserPicker
              userList={usersWithColors}
              selectedUsers={selectedUsers}
              setSeletedUsers={setSelectedUsers}
            ></UserPicker>
          </div>
        </Drawer>
      )}
    </div>
  );
};

const UserPicker = ({
  userList,
  setSeletedUsers,
  selectedUsers,
}: {
  userList: {
    userId: number;
    name: string | null;
    lastName: string | null;
    color: string;
  }[];
  selectedUsers: number[];
  setSeletedUsers: Dispatch<SetStateAction<number[]>>;
}) => {
  const toggleAll = () => {
    if (selectedUsers.length === userList.length) {
      setSeletedUsers([]);
      return;
    }
    setSeletedUsers(userList.map((u) => u.userId));
  };

  return (
    <Flex
      direction={"column"}
      gap={10}
      h={"100%"}
      className={classes.userSelectScroll}
    >
      <button
        className={`${classes.userRow} ${
          selectedUsers.length === userList.length ? classes.active : ""
        }`}
        onClick={toggleAll}
      >
        <Flex align={"center"} gap={10}>
          <Avatar size={30}>VSI</Avatar>
          <Text variant={TextVariant.CaptionEmphasized}>
            <Trans>Vsi zaposleni</Trans>
          </Text>
        </Flex>
        <RiCheckFill className={classes.check}></RiCheckFill>
      </button>
      {userList.map((u) => (
        <button
          key={u.userId}
          className={`${classes.userRow} ${
            selectedUsers.includes(u.userId) ? classes.active : ""
          }`}
          onClick={() => {
            setSeletedUsers((prev) => {
              const newState = [...prev];
              if (prev.includes(u.userId)) {
                newState.splice(newState.indexOf(u.userId), 1);
              } else {
                newState.push(u.userId);
              }

              // if (newState.length === 0) return prev;

              return newState;
            });
          }}
        >
          <Flex align={"center"} gap={10}>
            <Avatar size={30} color={u.color}>{`${u.name?.at(0) || ""}${
              u.lastName?.at(0) || ""
            }`}</Avatar>
            <Text
              variant={TextVariant.Caption}
            >{`${u.name} ${u.lastName}`}</Text>
          </Flex>
          <RiCheckFill className={classes.check}></RiCheckFill>
        </button>
      ))}
    </Flex>
  );
};
