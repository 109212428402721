import { t } from "@lingui/core/macro";
import { Autocomplete, AutocompleteProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";

type LimeAutocompleteProps<T extends object> = MantineFormValues &
  AutocompleteProps<T> & {
    emptyContentMessage?: string; // Allow customization of the empty content message
  };

export const LimeAutocomplete = <T extends object>({
  emptyContentMessage = t`Ni rezultatov`, // Default translated message
  listboxProps,
  error,
  ...props
}: LimeAutocompleteProps<T>) => {
  return (
    <Autocomplete<T>
      errorMessage={error}
      {...props}
      inputProps={{
        ...(props.inputProps || {}),
        style: {
          ...(props.inputProps?.style || {}),
          fontSize: isMobileSafari ? "16px" : undefined,
        },
      }}
      listboxProps={{
        ...(listboxProps || {}),
        emptyContent: emptyContentMessage, // Set the default or overridden message
      }}
    />
  );
};
