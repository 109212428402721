import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useEffect, useState } from "react";

import { LimeLoader } from "@/Components/LimeLoader";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import useFeature from "@/hooks/useFeature";
import { useLingui } from "@lingui/react";
import { Box, Tabs, Text } from "@mantine/core";
import { notifications as notifs } from "@mantine/notifications";
import { MdOutlineAdd } from "react-icons/md";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import UsersModal from "../../../Components/Modal/UsersModal";
import useAlertModal from "../../../Components/Modals/useAlertModal";
import PageContentTable from "../../../Components/PageContentTable/PageContentTable";
import {
  emailValid,
  numberAndCountryCodeToFullPhoneNumber,
} from "../../../Utilities";
import {
  apiAddUser,
  apiDeleteUser,
  apiGetUsers,
  apiUpdateUser,
} from "../../../apiCalls";
import useAccountInfo from "../../../hooks/useAccountInfo";
import useEscape from "../../../hooks/useEscape";
import { useFormFields } from "../../../hooks/useFormFields";
import usePreferredLanguageStore from "../../../stores/usePreferredLanguageStore";
import useUserPermissionsStore from "../../../stores/useUserPermissionsStore";
import useServices from "../Services/useServices";
import { ModalManageCustomValuesForServices } from "./ModalManageCustomValuesForServices";
import ModalManageNotificationsView from "./ModalManageNotificationsView";
import { ManagingPermissionsView } from "./ModalManagePermissionsView";
import { ManagingUserView } from "./ModalManagingUserView";
import useRoles from "./Roles/useRoles";
import { useUserStore } from "./userUserStore";
import { useMediaQuery } from "@mantine/hooks";

export const allPermissionPages = [
  "settings",
  "calendar",
  "analytics",
  "termini",
  "razpolozljivost",
  "storitve",
  "lokacija",
  "users",
  "resources",
  "tax_register",
];

export const isPageVisited = (page, visitedPages) => {
  if (page === "calendar") {
    return (
      visitedPages.includes("termini") &&
      visitedPages.includes("razpolozljivost") &&
      visitedPages.includes("storitve") &&
      visitedPages.includes("lokacija") &&
      visitedPages.includes("users")
    );
  }

  return visitedPages.includes(page);
};

const Users = () => {
  useLingui();
  const userPermissionsStore = useUserPermissionsStore();

  const [isEditingPasswordField, setIsEditingPasswordField] = useState(false);

  const { isFeatureEnabled } = useFeature();

  const { preferredLanguage, loadPreferredLanguage } =
    usePreferredLanguageStore((state) => ({
      preferredLanguage: state.preferredLanguage,
      loadPreferredLanguage: state.loadPreferredLanguage,
    }));
  const setDefaultPermissions = useUserPermissionsStore(
    (state) => state.setDefaultPermissions,
  );

  const [activeTab, setActiveTab] = useState("users"); //users = users, roles = roles

  const { alertModal } = useAlertModal();

  const defaultPermissions = useUserPermissionsStore(
    (state) => state.defaultPermissions,
  );

  const mergeUserPermissionsWithDefault = useUserPermissionsStore(
    (state) => state.mergeUserPermissionsWithDefault,
  );
  const accountInfo = useAccountInfo();

  const { users, setUsers, roles, setRoles } = useUserStore((state) => state);

  const [locations, setLocations] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { listOfRoles, openRolesModal, rolesModal } = useRoles();

  // modal
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    isUpdatingUser: false,
    isEditingPermissions: false,
    isEditingNotifications: false,
    isBackButton: false, // if true, show back button, else show cancel button
    isEditingCustomValuesForServices: false,
  });

  // User settings

  const [fields, setValues, , setFields] = useFormFields({
    userId: 0,
    name: "",
    lastName: "",
    email: "",
    password: "",
    description: "",
    phone: "",
    role: "",
    countryCode: "386",
    locations: [],
    preferred_language: preferredLanguage.clientPreferredLanguage,
    taxNumber: "",
    isSoleTrader: false,
  });

  const { services } = useServices(false);

  // const {data:users} = api.user.useUserList({clientId: accountInfo.clientId, });

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [permissions, setPermissions] = useState(defaultPermissions);
  const [notifications, setNotifications] = useState({});
  const [customValuesForServices, setCustomValuesForServices] = useState([]);

  const [visitedPermissionPages, setVisitedPermissionPages] = useState(
    !isMobile ? ["settings"] : [],
  );

  const isSignedInUser = accountInfo.accountInfo.userId === fields.userId;

  const areFieldsValid = () => {
    if (
      fields.name === "" ||
      fields.lastName === "" ||
      fields.email === "" ||
      !emailValid(fields.email) ||
      fields.password === "" /*||
      /!*    fields.phone === "" ||
      !isValidPhoneNumber(fields.phone) || *!/
      fields.role === ""*/
    ) {
      return false;
    }

    return true;
  };

  const HandleAddUserContinue = async () => {
    // CHECK FIELDS
    setHasSubmitted(true);

    if (!areFieldsValid()) {
      return;
    }

    setModalState((prevState) => ({
      ...prevState,
      isEditingPermissions: true,
    }));
  };

  const resetModal = () => {
    setFields({
      userId: 0,
      name: "",
      lastName: "",
      email: "",
      password: "",
      description: "",
      phone: "",
      role: "",
      locations: [],
      preferred_language: preferredLanguage.clientPreferredLanguage,
      taxNumber: "",
      isSoleTrader: false,
      shouldUpdatePassword: false,
    });
    setNotifications({});
    setModalState((prevState) => ({
      ...prevState,
      isModalOpen: false,
      isUpdatingUser: false,
      isEditingNotifications: false,
      isEditingPermissions: false,
      isEditingCustomValuesForServices: false,
    }));

    setPermissions(defaultPermissions);
    setVisitedPermissionPages(!isMobile ? ["settings"] : []);
    setCustomValuesForServices([]);
    setHasSubmitted(false);
    setIsEditingPasswordField(false);
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem("usr_token") || "";
    try {
      const response = await apiGetUsers(token);

      if (response.status !== 200) {
        notifs.show({
          message: t`Napaka pri pridobivanju uporabnikov`,
          title: t`Napaka`,
          color: "red",
        });
        return;
      }

      const newUsers = await response.data.users;
      const newRoles = await response.data.roles;
      const locations = await response.data.locations;
      const resources = await response.data.resources;
      setUsers(newUsers);
      setRoles(newRoles);
      setLocations(locations);
      setResources(resources);

      setDefaultPermissions(response.data.defaultPermissions);
    } catch (e) {
      console.log(e);
    }
  };

  const addUser = async () => {
    setHasSubmitted(true);
    if (!areFieldsValid()) {
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("usr_token") || "";

    let phone = "";
    let countryCode = "386";

    try {
      if (fields.phone) {
        phone = parsePhoneNumber(fields.phone)
          .formatNational()
          .replaceAll(" ", "")
          .trim();
        countryCode = parsePhoneNumber(fields.phone).countryCallingCode;
      }
    } catch (e) {
      setIsLoading(false);
      notifs.show({
        type: "error",
        message: e.response?.data || t`Napaka pri dodajanju uporabnika -- 0`,
        title: t`Napaka`,
        color: "red",
      });
      return;
    }

    const data = {
      ...fields,
      phone,
      countryCode,
      permissions: permissions,
    };

    const response = await apiAddUser(data, token).catch((e) => {
      notifs.show({
        type: "error",
        message: e?.response?.data || t`Napaka pri dodajanju uporabnika`,
        title: t`Napaka`,
        color: "red",
      });
      setIsLoading(false);
    });

    if (response == null || response.status !== 200) {
      setIsLoading(false);
      return;
    }

    fetchUsers().then(() => {
      setIsLoading(false);
    });

    notifs.show({
      type: "success",
      message: t`Oseba je uspešno dodana`,
    });

    resetModal();
    setHasSubmitted(false);
  };

  const confirmDeleteUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("usr_token") || "";

    const data = {
      userId: fields.userId,
    };

    try {
      const response = await apiDeleteUser(data, token);

      if (response.status !== 200) {
        notifs.show({
          message: t`Napaka pri brisanju uporabnika`,
          title: t`Napaka`,
          color: "red",
        });
        return;
      }

      fetchUsers().then(() => {
        setIsLoading(false);
      });

      resetModal();

      notifs.show({
        message: t`Oseba je uspešno izbrisana`,
        title: t`Uspešno`,
      });
    } catch (e) {
      setIsLoading(false);

      notifs.show({
        message: e.response?.data || t`Napaka pri brisanju uporabnika`,
        title: t`Napaka`,
        color: "red",
      });
    }
  };

  const deleteUser = async () =>
    alertModal(
      "Izbriši osebo",
      <Text size="sm">
        <Trans>Ali ste prepričani, da želite izbrisati osebo</Trans>{" "}
        <Text span fw={"bold"}>
          {fields?.name} {fields?.lastName}
        </Text>
        ?
      </Text>,
      {
        labelConfirm: t`Da, izbriši osebo`,
        labelCancel: t`Ne, prekliči brisanje`,
      },
      confirmDeleteUser,
    );

  const updateUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("usr_token") || "";

    try {
      let phone = "";
      let countryCode = "386";

      if (fields.phone) {
        phone = parsePhoneNumber(fields.phone)
          .formatNational()
          .replaceAll(" ", "")
          .trim();

        countryCode = parsePhoneNumber(fields.phone).countryCallingCode;
      }

      const fieldsForRequest = { ...fields, phone, countryCode };

      const role = fields.role;

      if (role && !isNaN(role)) {
        if (!roles.find((r) => r.value === role)) {
          fieldsForRequest.role = null;
        }
      }

      if (!notifications.additional_notifications_enabled) {
        notifications.locations = [];
        notifications.services = [];
        notifications.users = [];
        notifications.all_locations = 1;
        notifications.all_services = 1;
        notifications.all_users = 1;
      }

      const data = {
        ...fieldsForRequest,
        permissions: permissions,
        notifications: notifications,
        customValuesForServices,
        shouldUpdatePassword:
          isEditingPasswordField || !modalState.isUpdatingUser,
      };

      const response = await apiUpdateUser(data, token);

      fetchUsers().then(() => {
        setIsLoading(false);
      });

      setModalState((prevState) => ({
        ...prevState,
        isModalOpen: false,
        isUpdatingUser: false,
        isEditingNotifications: false,
        isEditingPermissions: false,
      }));
      // update permissions locally if user is updating himself
      if (isSignedInUser) {
        userPermissionsStore.setFullPermissions(permissions);
      }
      loadPreferredLanguage();
      notifs.show({
        title: t`Oseba je uspešno posodobljena`,
      });
    } catch (e) {
      console.log(e);

      notifs.show({
        title: t`Napaka`,
        message: e.response?.data || t`Napaka pri posodabljanju uporabnika`,
        color: "red",
      });

      setIsLoading(false);
    }
  };

  const toggleSelectedUser = (userId) => {
    const temp = [...selectedUsers];

    const user = temp.find((c) => c.id === userId);

    if (user) {
      user.isSelected = !user.isSelected;
    } else {
      temp.push({ id: userId, isSelected: true });
    }

    setSelectedUsers(temp);
  };

  const selectRow = (user) => {
    setModalState((prevState) => ({
      ...prevState,
      isModalOpen: true,
      isUpdatingUser: true,
    }));

    const phone =
      user.phone &&
      isValidPhoneNumber(
        numberAndCountryCodeToFullPhoneNumber(user.phone, user.countryCode),
      )
        ? numberAndCountryCodeToFullPhoneNumber(user.phone, user.countryCode)
        : "";

    setFields({
      userId: user.userId,
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      phone: phone,
      role: user.role,
      countryCode: user.countryCode,
      locations: user.locations,
      preferred_language: user.preferred_language,
      description: user.description,
      taxNumber: user.taxNumber,
      isSoleTrader: user.isSoleTrader,
    });
    setPermissions(user.permissions);
    setCustomValuesForServices(user.services);

    const hasAdditionalNotifications =
      user.notifications.locations.length > 0 ||
      user.notifications.users.length > 0 ||
      user.notifications.services.length > 0;

    setNotifications({
      ...user.notifications,
      additional_notifications_enabled: hasAdditionalNotifications,
    });
  };

  useEscape(() => {
    setModalState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
  });

  const toggleAllSelected = () => {
    const temp = [...selectedUsers];

    if (temp.every((c) => c.isSelected)) {
      temp.forEach((c) => (c.isSelected = false));
    } else {
      temp.forEach((c) => (c.isSelected = true));
    }

    setSelectedUsers(temp);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUsers().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!modalState.isModalOpen) {
      resetModal();
      return;
    }

    const user = users.find((u) => u.userId === fields.userId);

    if (user) {
      setPermissions(mergeUserPermissionsWithDefault(user.permissions));
    } else {
      setPermissions(defaultPermissions);
    }
  }, [modalState.isModalOpen]);

  let modalTitle = t`Dodaj osebo`;
  if (modalState.isUpdatingUser) {
    modalTitle = t`Uredi osebo - ${fields.name} ${fields.lastName}`;
  } else if (modalState.isEditingPermissions) {
    modalTitle = t`Urejanje dovoljenj osebe`;
  } else if (modalState.isEditingNotifications) {
    modalTitle = t`Urejanje obvestil osebe`;
  }

  const user = users.find((u) => u.userId === fields.userId);

  const tabs = [
    {
      label: t`Pregled osebja`,
      value: "users",
    },
  ];

  if (isFeatureEnabled("USER_ROLES")) {
    tabs.push({
      label: t`Vloge osebja`,
      value: "roles",
    });
  }

  const allPermissionsFiltered = allPermissionPages.filter((permission) => {
    if (permission === "resources") {
      return isFeatureEnabled("RESOURCES");
    }
    if (permission === "tax_register") {
      return isFeatureEnabled("TAX_REGISTER");
    }
    return true;
  });

  const hasVisitedAllPermissions =
    allPermissionsFiltered.length === visitedPermissionPages.length;

  return (
    <>
      {/* <PageHeader
        title={t`Osebje`}
        subHeader={{
          tabs,
          selectedTab: activeTab,
          onSelectTab: (tab) => {
            setActiveTab(tab);
          },
        }}
      /> */}

      <LimePageHeader
        title={t`Osebje`}
        rightSection={
          activeTab === "users"
            ? {
                label: t`Dodaj osebo`,
                onClick: () => {
                  setModalState((prevState) => ({
                    ...prevState,
                    isModalOpen: true,
                  }));
                },
                icon: <MdOutlineAdd size={"1.3rem"} />,
              }
            : {
                label: t`Dodaj vlogo`,
                onClick: openRolesModal,
                icon: <MdOutlineAdd size={"1.3rem"} />,
              }
        }
      />

      <LimePageTabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        rightSection={<div />}
      />

      {isLoading ? (
        <LimeLoader></LimeLoader>
      ) : (
        <Box px={isMobile ? undefined : "xl"}>
          <UsersModal
            isMobile={isMobile}
            update={modalState.isUpdatingUser}
            opened={modalState.isModalOpen}
            closeHandle={resetModal}
            title={modalTitle}
            isEditingPermissions={modalState.isEditingPermissions}
            isEditingNotifications={modalState.isEditingNotifications}
            isEditingCustomValuesForServices={
              modalState.isEditingCustomValuesForServices
            }
          >
            {!modalState.isEditingPermissions &&
              !modalState.isEditingNotifications &&
              !modalState.isEditingCustomValuesForServices && (
                <ManagingUserView
                  locations={locations}
                  services={services}
                  roles={roles}
                  editingAdmin={
                    users.filter((u) => u.admin === true).length === 1 &&
                    users.filter((u) => u.admin === true).at(0).userId ===
                      fields.userId
                  }
                  userServices={customValuesForServices}
                  closeHandle={resetModal}
                  user={user}
                  hasSubmitted={hasSubmitted}
                  deleteUser={deleteUser}
                  addUserUpdate={updateUser}
                  permissions={permissions}
                  updateUser={modalState.isUpdatingUser}
                  setIsEditingPermissions={(value) => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isEditingPermissions: value,
                    }));
                  }}
                  setIsEditingCustomValuesForServices={(value) => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isEditingCustomValuesForServices: value,
                    }));
                  }}
                  setIsEditingNotifications={(value) => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isEditingNotifications: value,
                    }));
                  }}
                  fields={fields}
                  setValues={setValues}
                  handleAddUserContinue={HandleAddUserContinue}
                  isSignedInUser={isSignedInUser}
                  hasVisitedAllPermissions={hasVisitedAllPermissions}
                  addUser={addUser}
                  setRoles={setRoles}
                  isEditingPasswordField={isEditingPasswordField}
                  setIsEditingPasswordField={setIsEditingPasswordField}
                />
              )}

            {modalState.isEditingPermissions && (
              <ManagingPermissionsView
                disabled={
                  users.filter((u) => u.admin === true).length === 1 &&
                  users.filter((u) => u.admin === true).at(0).userId ===
                    fields.userId
                }
                user={user}
                permissions={permissions}
                setPermissions={setPermissions}
                visitedPermissionPages={visitedPermissionPages}
                setVisitedPermissionPages={setVisitedPermissionPages}
                isMobile={isMobile}
                setIsManagingPermissions={(value) => {
                  setModalState((prevState) => ({
                    ...prevState,
                    isEditingPermissions: value,
                  }));
                }}
                isBackButton={modalState.isBackButton}
                setIsBackButton={(value) => {
                  setModalState((prevState) => ({
                    ...prevState,
                    isBackButton: value,
                  }));
                }}
              />
            )}
            {modalState.isEditingNotifications && (
              <ModalManageNotificationsView
                notifications={notifications}
                setNotifications={setNotifications}
                isMobile={isMobile}
                setIsManagingNotifications={(value) => {
                  setModalState((prevState) => ({
                    ...prevState,
                    isEditingNotifications: value,
                  }));
                }}
                isBackButton={modalState.isBackButton}
                setIsBackButton={(value) => {
                  setModalState((prevState) => ({
                    ...prevState,
                    isBackButton: value,
                  }));
                }}
                services={services}
                locations={locations}
                users={users}
                resources={resources}
                isResourcesFeatureEnabled={isFeatureEnabled("RESOURCES")}
              />
            )}

            {modalState.isEditingCustomValuesForServices &&
              customValuesForServices.length > 0 && (
                <ModalManageCustomValuesForServices
                  customValuesForServices={customValuesForServices}
                  setCustomValuesForServices={setCustomValuesForServices}
                  isMobile={isMobile}
                  setIsManagingCustomValuesForServices={(value) => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isEditingCustomValuesForServices: value,
                    }));
                  }}
                  isBackButton={modalState.isBackButton}
                  setIsBackButton={(value) => {
                    setModalState((prevState) => ({
                      ...prevState,
                      isBackButton: value,
                    }));
                  }}
                  services={services}
                  locations={locations}
                  users={users}
                />
              )}
          </UsersModal>
          {rolesModal}
          {users.length === 0 && (
            <>
              <p style={{ textAlign: "left" }}>
                <Trans>Ni oseb za prikazati!</Trans>
              </p>
            </>
          )}
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.Panel value={"users"}>
              <PageContentTable
                checkboxSelectors={false}
                objects={users}
                toggleAllSelected={toggleAllSelected}
                isMobile={isMobile}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                selectRow={selectRow}
                toggleSelectedObject={toggleSelectedUser}
                idValue={"userId"}
                values={users.map((u) => [
                  {
                    label: t`Ime`,
                    value: u.name,
                  },
                  {
                    label: t`Priimek`,
                    value: u.lastName,
                  },
                  {
                    label: t`Email`,
                    value: u.email,
                  },
                  {
                    label: t`Telefon`,
                    value: u.phone,
                  },
                  {
                    label: t`Vloga`,
                    value: roles.find((r) => r.value === u.role)?.label,
                  },
                ])}
              />
            </Tabs.Panel>
            <Tabs.Panel value={"roles"}>{listOfRoles}</Tabs.Panel>
          </Tabs>
        </Box>
      )}
    </>
  );
};

export default Users;
