export const countries: {
  name: string;
  code: string;
  flag: string;
}[] = [
  {
    name: "Slovenija",
    code: "SI",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
  },
  {
    name: "Hrvaška",
    code: "HR",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
  },
  {
    name: "Srbija",
    code: "SR",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Flag_of_Serbia.svg/2880px-Flag_of_Serbia.svg.png",
  },
  {
    name: "Združeni arabski emirati",
    code: "AE",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
  },
  {
    name: "Bosna in Hercegovina",
    code: "BA",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Bosnia_and_Herzegovina.svg",
  },
];
