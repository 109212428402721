import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { BillingPeriodType } from "@/server-types";
import { TextVariant } from "@/types/text-variants";
import { cn } from "@/utils";
import {
  Accordion,
  ActionIcon,
  Button,
  ComboboxData,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm, UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import { LuPlus, LuTrash } from "react-icons/lu";

type ClientBillingForm = {
  taxNumber: string | null;
  billingStartDate: Date;
  billingPeriodType: BillingPeriodType | null;
  billingPeriodInterval: number;
  pricingTierId: string;
  pricePerLocation: number;
  pricePerUser: number;
  pricePerSms: number;
  pricePerResource: number;
  additionalSms: number;
  additionalLocations: {
    id: number | null;
    price: number;
    isRemoved: boolean;
  }[];
  additionalUsers: {
    id: number | null;
    price: number;
    isRemoved: boolean;
  }[];
  additionalResources: {
    id: number | null;
    price: number;
    isRemoved: boolean;
  }[];
  priceForSenderId: number;
  priceBase: number;
  discountAmount: number;
};

export const BillingSettings = ({
  clientId,
  refetchPrice,
}: {
  clientId: number;
  refetchPrice: () => void;
}) => {
  const { data, refetch: refetchBillingData } =
    api.admin.useGetClientBilling(clientId);

  const {
    mutateAsync: updateClientBilling,
    isPending: isUpdatePending,
    isError: isUpdateError,
  } = api.admin.usePutClientBilling();

  const {
    mutateAsync: activateBilling,
    isPending: isActivatePending,
    isError,
  } = api.admin.useActivateBilling();
  const { mutateAsync: deactivateBilling, isPending: isDeactivatePending } =
    api.admin.useDeactivateBilling();

  const form = useForm<ClientBillingForm>({
    initialValues: {
      additionalLocations: [],
      additionalUsers: [],
      additionalResources: [],
      billingPeriodInterval: 1,
      billingPeriodType: null,
      taxNumber: null,
      pricingTierId: "",
      billingStartDate: dayjs().utc(true).toDate(),
      pricePerLocation: 0,
      pricePerUser: 0,
      pricePerSms: 0,
      pricePerResource: 0,
      priceBase: 0,
      priceForSenderId: 0,
      additionalSms: 0,
      discountAmount: 0,
    },
  });

  useEffect(() => {
    if (data) {
      const { billingInfo } = data;

      form.initialize({
        ...billingInfo,
        pricingTierId: billingInfo.pricingTier.pricingTierId,
        billingPeriodInterval: billingInfo.billingPeriodInterval ?? 1,
        billingStartDate: billingInfo.billingStartDate
          ? dayjs(billingInfo.billingStartDate).utc(true).toDate()
          : dayjs().utc(true).toDate(),
        pricePerLocation: billingInfo.pricePerLocationCents / 100,
        pricePerUser: billingInfo.pricePerUserCents / 100,
        pricePerSms: billingInfo.pricePerSmsCents / 100,
        pricePerResource: billingInfo.pricePerResourceCents / 100,
        additionalLocations: billingInfo.ClientAdditionalLocation.map(
          (loc) => ({
            id: loc.id,
            price: loc.priceCents / 100,
            isRemoved: false,
          }),
        ),
        additionalUsers: billingInfo.ClientAdditionalUsers.map((user) => ({
          id: user.id,
          price: user.priceCents / 100,
          isRemoved: false,
        })),
        additionalResources: billingInfo.ClientAdditionalResources.map(
          (resource) => ({
            id: resource.id,
            price: resource.priceCents / 100,
            isRemoved: false,
          }),
        ),
        priceBase: billingInfo.priceBaseCents / 100,
        priceForSenderId: billingInfo.priceForSenderIdCents / 100,
      });
    }
  }, [data, form]);

  if (!data) return <div>Loading...</div>;

  const billingPeriodTypeData: ComboboxData = [
    { label: "Dan", value: "day" },
    { label: "Teden", value: "week" },
    { label: "Mesec", value: "month" },
    { label: "Leto", value: "year" },
  ];

  const handleSaveBillingData = async (values: ClientBillingForm) => {
    // if (
    //   values.billingPeriodType === null ||
    //   values.billingPeriodInterval === null ||
    //   values.billingStartDate === null ||
    //   values.taxNumber === null
    // ) {
    //   return;
    // }

    await updateClientBilling({
      params: {
        clientId,
      },
      body: {
        ...(values as {
          taxNumber: string;
          billingPeriodType: "day" | "week" | "month" | "year";
          billingPeriodInterval: number;
        }),
        billingStartDate: dayjs(values.billingStartDate)
          .utc(true)
          .format("YYYY-MM-DD"),
        pricingTierId: values.pricingTierId,
        additionalLocations: values.additionalLocations,
        additionalUsers: values.additionalUsers,
        additionalResources: values.additionalResources,
        pricePerLocation: values.pricePerLocation,
        pricePerUser: values.pricePerUser,
        pricePerSms: values.pricePerSms,
        pricePerResource: values.pricePerResource,
        priceBase: values.priceBase,
        priceForSenderId: values.priceForSenderId,
        additionalSms: values.additionalSms,
        discountAmount: values.discountAmount,
      },
    });

    notifications.show({ message: "Posodobljeno", color: "green" });

    refetchBillingData();
    refetchPrice();
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSaveBillingData)}
      className="flex flex-col gap-4 p-4"
    >
      <div>Status obračunavanja: {data.billingInfo.billingStatus}</div>
      <div>Partner ID : {data.billingInfo.externalPartnerId}</div>

      <div className="flex gap-6">
        <NumberInput
          label={t`Cena za lokacijo (EUR)`}
          {...form.getInputProps("pricePerLocation")}
        ></NumberInput>

        <NumberInput
          label={t`Cena za uporabnika (EUR)`}
          {...form.getInputProps("pricePerUser")}
        ></NumberInput>

        <NumberInput
          label={t`Cena za SMS (EUR)`}
          {...form.getInputProps("pricePerSms")}
        ></NumberInput>

        <NumberInput
          label={t`Cena za resurs (EUR)`}
          {...form.getInputProps("pricePerResource")}
        ></NumberInput>

        <NumberInput
          label={t`Cena za sender ID (EUR)`}
          {...form.getInputProps("priceForSenderId")}
        ></NumberInput>

        <NumberInput
          label={t`Dodatnih SMSjev`}
          {...form.getInputProps("additionalSms")}
        ></NumberInput>
      </div>

      <div className="flex gap-6">
        <Select
          label={t`Paket`}
          allowDeselect={false}
          data={data.pricingTiers.map((t) => ({
            value: t.pricingTierId,
            label: t.tierName,
          }))}
          {...form.getInputProps("pricingTierId")}
          onChange={(val) => {
            form.setFieldValue("pricingTierId", val!);
            if (val !== form.values.pricingTierId) {
              const pricingTier = data.pricingTiers.find(
                (t) => t.pricingTierId === val,
              )!;

              modals.openConfirmModal({
                title: "Preveri",
                children: (
                  <Text>
                    Spremeni ceno na {pricingTier.tierPriceCents / 100} ?
                  </Text>
                ),
                labels: { confirm: "Potrdi", cancel: "Prekliči" },
                onConfirm: () => {
                  form.setFieldValue(
                    "priceBase",
                    pricingTier.tierPriceCents / 100,
                  );
                },
              });
            }
          }}
        ></Select>

        <NumberInput
          label={t`Cena osnova (EUR)`}
          {...form.getInputProps("priceBase")}
        ></NumberInput>
      </div>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalUsers"
        title="Uporabniki"
        billingInfoField="ClientAdditionalUsers"
        pricingTierItemLimit={data.billingInfo.pricingTier?.maxUserCount ?? 0}
      ></AdditionalsAccordion>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalLocations"
        title="Lokacije"
        billingInfoField="ClientAdditionalLocation"
        pricingTierItemLimit={
          data.billingInfo.pricingTier?.maxLocationCount ?? 0
        }
      ></AdditionalsAccordion>

      <AdditionalsAccordion
        form={form}
        billingInfo={data.billingInfo}
        formField="additionalResources"
        title="Resourci"
        billingInfoField="ClientAdditionalResources"
        pricingTierItemLimit={
          data.billingInfo.pricingTier?.maxResourceCount ?? 0
        }
      ></AdditionalsAccordion>

      <TextInput
        label={t`Davčna številka`}
        {...form.getInputProps("taxNumber")}
      ></TextInput>
      <div className="flex flex-col gap-1">
        <Text variant={TextVariant.BodyEmphasized}>Obračunavanje</Text>
        <div className="flex items-center gap-2">
          <Text>Na </Text>
          <NumberInput
            min={1}
            {...form.getInputProps("billingPeriodInterval")}
            className="w-16"
          ></NumberInput>
          <Select
            {...form.getInputProps("billingPeriodType")}
            data={billingPeriodTypeData}
          ></Select>

          <Text>Popust </Text>
          <NumberInput
            {...form.getInputProps("discountAmount")}
            className="w-16"
          ></NumberInput>
        </div>
      </div>
      <DateInput
        label={t`Začetek obračunavanja`}
        {...form.getInputProps("billingStartDate")}
        disabled={data.billingInfo.billingStatus === "active"}
      ></DateInput>

      <div className="flex gap-4">
        <Button type="submit" loading={isUpdatePending}>
          Shrani
        </Button>
        {data.billingInfo.billingStatus === "inactive" ? (
          <Button
            onClick={async () => {
              await activateBilling({ params: { clientId } });

              notifications.show({ message: "Aktivirano", color: "green" });

              refetchBillingData();
            }}
            loading={isActivatePending || isDeactivatePending}
            disabled={
              data.billingInfo.billingPeriodInterval === null ||
              data.billingInfo.billingPeriodType === null ||
              data.billingInfo.billingStartDate === null ||
              data.billingInfo.taxNumber === null ||
              !["PRO", "BASIC", "ADVANCED", "ENTERPRISE"].includes(
                data.billingInfo.pricingTier.pricingTierId,
              )
            }
          >
            Aktiviraj obračunavanje
          </Button>
        ) : (
          <Button
            onClick={async () => {
              await deactivateBilling({ params: { clientId } });

              notifications.show({ message: "Deaktivirano", color: "green" });

              refetchBillingData();
            }}
          >
            Deaktiviraj obračunavanje
          </Button>
        )}
      </div>
    </form>
  );
};

const AdditionalsAccordion = ({
  form,
  billingInfo,
  formField,
  title,
  billingInfoField,
  pricingTierItemLimit,
}: {
  form: UseFormReturnType<
    ClientBillingForm,
    (values: ClientBillingForm) => ClientBillingForm
  >;
  formField: "additionalLocations" | "additionalUsers" | "additionalResources";
  title: string;
  billingInfoField:
    | "ClientAdditionalLocation"
    | "ClientAdditionalUsers"
    | "ClientAdditionalResources";
  billingInfo: {
    ClientAdditionalLocation: {
      id: number | null;
      priceCents: number;
    }[];
    ClientAdditionalUsers: {
      id: number | null;
      priceCents: number;
    }[];
    ClientAdditionalResources: {
      id: number | null;
      priceCents: number;
    }[];
    pricePerLocationCents: number;
  };
  pricingTierItemLimit: number;
}) => {
  return (
    <Accordion>
      <Accordion.Item value="tax-number">
        <Accordion.Control>
          <Text variant={TextVariant.Body}>
            {title}: ( {pricingTierItemLimit} free +{" "}
            {billingInfo[billingInfoField].length} dodatnih ={" "}
            <b>
              {billingInfo[billingInfoField].reduce((acc, loc) => {
                return acc + loc.priceCents / 100;
              }, 0)}
              EUR
            </b>{" "}
            )
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <div className="flex flex-col gap-4">
            <Table>
              <Table.Thead>
                <Table.Th>Številka</Table.Th>
                <Table.Th>Cena v EUR</Table.Th>
                <Table.Th>Izbriši</Table.Th>
              </Table.Thead>
              <Table.Tbody>
                {form.values[formField].map((l, index) => (
                  <Table.Tr
                    key={l.id}
                    className={cn(l.isRemoved ? "opacity-50" : "")}
                  >
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>
                      <NumberInput
                        className="w-50"
                        {...form.getInputProps(`${formField}.${index}.price`)}
                      ></NumberInput>
                    </Table.Td>
                    <Table.Td>
                      <ActionIcon
                        onClick={() => {
                          const item = form.values[formField][index];
                          if (!item.id) {
                            form.setFieldValue(
                              formField,
                              form.values[formField].filter(
                                (loc) => loc !== item,
                              ),
                            );
                          } else {
                            if (item.isRemoved) {
                              item.isRemoved = false;
                            } else {
                              item.isRemoved = true;
                            }
                            form.setFieldValue(
                              formField,
                              form.values[formField].map((loc) => {
                                if (loc === item) {
                                  return item;
                                }
                                return loc;
                              }),
                            );
                          }
                        }}
                      >
                        {l.isRemoved ? <LuPlus /> : <LuTrash />}
                      </ActionIcon>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
          <ActionIcon
            onClick={() => {
              form.insertListItem(formField, {
                id: null,
                price: form.values.pricePerLocation,
                isRemoved: false,
              });
            }}
          >
            <LuPlus />
          </ActionIcon>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
