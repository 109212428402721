import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useForm, UseFormReturnType } from "@mantine/form";
import { z } from "zod";
import {
  AutocompleteItem,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@heroui/react";
import { api } from "@/lib/api-client";
import { useEffect, useState } from "react";
import { useDebouncedState } from "@mantine/hooks";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { Pencil, SearchIcon, Trash } from "lucide-react";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { manageDeliveryOrderSchema } from "./utils";
import { formatCurrency } from "../../../../../../shared/utils/utils";
import { i18n } from "@lingui/core";
import { ProductCard } from "./shared";
import { LimeAutocomplete } from "@/Components/NextBase/LimeAutocomplete";

export const ProductsTab = ({
  form,
  handleNextStep,
  netPriceTotalWithoutTotalDiscount,
  grossPriceTotalWithoutTotalDiscount,
  fieldsToValidate,
}: {
  form: UseFormReturnType<z.infer<typeof manageDeliveryOrderSchema>>;
  handleNextStep: () => void;
  netPriceTotalWithoutTotalDiscount: number;
  grossPriceTotalWithoutTotalDiscount: number;
  fieldsToValidate: (keyof z.infer<typeof manageDeliveryOrderSchema>)[];
}) => {
  const [editingProductIndexInArray, setEditingProductIndexInArray] = useState<
    number | null
  >(null);

  const { data: products } = api.product.useGetProducts({
    productType: "PURCHASE",
  });

  const { alertModal } = useLimeAlertModal();

  const { locale } = i18n;

  const {
    isOpen: isProductPropertiesModalOpen,
    onOpen: onOpenProductPropertiesModal,
    onClose: onCloseProductPropertiesModal,
  } = useDisclosure();

  const { data: currency } = api.values.useGetCurrency({
    locationId: form.getValues().locationId,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const hasErrors = fieldsToValidate.some(
      (field) => form.validateField(field).hasError,
    );

    if (!hasErrors) {
      handleNextStep();
    }
  };

  const handleRemoveProduct = (index: number) => {
    form.setFieldValue(
      "products",
      form.getValues().products.filter((_, i) => i !== index),
    );
  };

  const handleSubmitProductProperties = (
    values?: ProductPropertiesModalForm,
  ) => {
    if (!values) {
      setEditingProductIndexInArray(null);
      onCloseProductPropertiesModal();
      return;
    }

    if (editingProductIndexInArray != null) {
      form.setFieldValue(
        "products",
        form
          .getValues()
          .products.map((product, index) =>
            index === editingProductIndexInArray ? values : product,
          ),
      );
    } else {
      form.setFieldValue("products", [...form.getValues().products, values]);
    }

    setEditingProductIndexInArray(null);
    onCloseProductPropertiesModal();
  };

  const netPriceTotalWithoutDiscountFormatted = formatCurrency({
    amount: netPriceTotalWithoutTotalDiscount,
    currency: currency || "EUR",
    locale,
  });

  const grossPriceTotalWithoutTotalDiscountFormatted = formatCurrency({
    amount: grossPriceTotalWithoutTotalDiscount,
    currency: currency || "EUR",
    locale,
  });

  const taxValue =
    Math.round(
      (grossPriceTotalWithoutTotalDiscount -
        netPriceTotalWithoutTotalDiscount) *
        100,
    ) / 100;
  const taxValueFormatted = formatCurrency({
    amount: taxValue,
    currency: currency || "EUR",
    locale,
  });

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex w-full flex-col items-center"
      >
        <div className="mt-2 flex w-full max-w-[450px] flex-1 flex-col gap-3 px-4">
          <Button color="primary" onPress={onOpenProductPropertiesModal}>
            <Trans>Dodaj produkt na dobavnico</Trans>
          </Button>

          <Divider />

          <div className="flex flex-col gap-4 pb-[164px]">
            {form.getValues().products.map((product, index) => (
              <ProductCard
                key={product.productId}
                index={index}
                locale={locale}
                product={product}
                products={products}
                currency={currency}
                onEditClick={() => {
                  setEditingProductIndexInArray(index);
                  onOpenProductPropertiesModal();
                }}
                onRemoveClick={() => {
                  alertModal({
                    onConfirm: () => {
                      handleRemoveProduct(index);
                    },
                    title: t`Izbriši produkt iz dobavnice?`,
                    content: t`Tega ni mogoče razveljaviti`,
                  });
                }}
              />
            ))}
          </div>
        </div>
        <div className="fixed bottom-0 z-[99999] w-full max-w-[450px] bg-background p-4">
          <div className="mb-4 flex flex-col gap-2 text-sm">
            <div className="flex justify-between">
              <p className="font-medium">
                <Trans>Neto cena skupaj</Trans>
              </p>
              <p>{netPriceTotalWithoutDiscountFormatted}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-medium">
                <Trans>Bruto cena skupaj</Trans>
              </p>
              <p>{grossPriceTotalWithoutTotalDiscountFormatted}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-medium">
                <Trans>Vrednost davka</Trans>
              </p>
              <p>{taxValueFormatted}</p>
            </div>
          </div>

          <Button
            type="submit"
            color="primary"
            className="w-full"
            isDisabled={form.getValues().products.length === 0}
          >
            Naprej
          </Button>
        </div>
      </form>

      {products?.products ? (
        <ProductPropertiesModal
          existingProductProperties={
            editingProductIndexInArray != null
              ? form.getValues().products[editingProductIndexInArray]
              : undefined
          }
          isOpen={isProductPropertiesModalOpen}
          onClose={handleSubmitProductProperties}
        ></ProductPropertiesModal>
      ) : undefined}
    </>
  );
};

type ProductPropertiesModalForm = z.infer<
  typeof manageDeliveryOrderSchema
>["products"][number];
const ProductPropertiesModal = ({
  existingProductProperties,
  isOpen,
  onClose,
}: {
  existingProductProperties?: ProductPropertiesModalForm;
  isOpen: boolean;
  onClose: (values?: ProductPropertiesModalForm) => void;
}) => {
  const [search, setSearch] = useDebouncedState("", 300);

  const [manualChanged, setManualChanged] = useState<"net" | "gross">("net");

  const { data: products, isFetching: isProductsFetching } =
    api.product.useGetProducts({
      search,
      limit: 10,
      productType: "PURCHASE",
    });

  const form = useForm<ProductPropertiesModalForm>({
    initialValues: {
      productId: -1,
      quantity: 1,
      discountPercentage: 0,
      netPricePer: 0,
      taxPercentage: 0,
      grossPricePer: 0,
    },

    validate: {
      quantity: (value) => (value <= 0 ? t`Vnesite kolicino` : null),
      productId: (value) =>
        products?.products.find((p) => p.id === value)
          ? null
          : t`Izberite produkt`,
    },
  });

  useEffect(() => {
    if (!existingProductProperties) return;

    form.setValues(existingProductProperties);
  }, [existingProductProperties]);

  const handleSubmit = (values: ProductPropertiesModalForm) => {
    form.reset();
    setSearch("");
    onClose(values);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        form.reset();
        setSearch("");
        onClose();
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalContent className="flex flex-col gap-4">
          <ModalHeader>
            <Trans>Dodajanje produkta</Trans>
          </ModalHeader>
          <ModalBody>
            <LimeAutocomplete
              label={t`Izberi produkt`}
              onInputChange={setSearch}
              value={search}
              errorMessage={form.errors.productId}
              isInvalid={!!form.errors.productId}
              items={products?.products || []}
              selectedKey={
                form.getValues().productId > -1
                  ? form.getValues().productId.toString()
                  : undefined
              }
              onSelectionChange={(id) => {
                form.setFieldValue("productId", Number(id));

                const productDetails = products?.products.find(
                  (p) => p.id === Number(id),
                );

                if (!productDetails || !productDetails.purchaseProductData)
                  return;

                form.setValues({
                  taxPercentage: Number(
                    productDetails.purchaseProductData.taxPercentage,
                  ),
                  netPricePer:
                    Number(productDetails.purchaseProductData.netPriceCents) /
                    100,
                  grossPricePer:
                    Number(productDetails.purchaseProductData.grossPriceCents) /
                    100,
                });
              }}
              isLoading={isProductsFetching}
              startContent={
                <div className="pointer-events-none flex items-center">
                  <SearchIcon
                    className="text-small text-default-400"
                    size={"15px"}
                  />
                </div>
              }
            >
              {(item) => (
                <AutocompleteItem key={item.id.toString()}>
                  {item.name}
                </AutocompleteItem>
              )}
            </LimeAutocomplete>

            <LimeInput
              {...form.getInputProps("quantity")}
              label={t`Kolicina`}
              type="number"
            ></LimeInput>

            <LimeInput
              {...form.getInputProps("netPricePer")}
              value={form.values.netPricePer.toString()}
              onValueChange={(value) => {
                const newNetValue = Number(value);
                const newGrossValue =
                  newNetValue * (1 + form.values.taxPercentage / 100);

                form.setValues({
                  netPricePer: newNetValue,
                  grossPricePer: Math.round(newGrossValue * 100) / 100,
                });
                setManualChanged("net");
              }}
              label={t`Neto cena na kos`}
              type="number"
            ></LimeInput>

            <LimeInput
              value={form.values.grossPricePer.toString()}
              onValueChange={(value) => {
                const newGrossValue = Number(value);
                const newNetValue =
                  newGrossValue / (1 + form.values.taxPercentage / 100);

                form.setValues({
                  netPricePer: Math.round(newNetValue * 100) / 100,
                  grossPricePer: newGrossValue,
                });
                setManualChanged("gross");
              }}
              label={t`Bruto cena na kos`}
              type="number"
            ></LimeInput>

            <LimeInput
              value={form.values.taxPercentage.toString()}
              onValueChange={(value) => {
                const newTax = Number(value);
                if (manualChanged === "net") {
                  const newGrossValue =
                    form.values.netPricePer * (1 + newTax / 100);

                  form.setFieldValue(
                    "grossPricePer",
                    Math.round(newGrossValue * 100) / 100,
                  );
                } else if (manualChanged === "gross") {
                  const newNetValue =
                    form.values.grossPricePer / (1 + newTax / 100);
                  form.setFieldValue(
                    "netPricePer",
                    Math.round(newNetValue * 100) / 100,
                  );
                }

                form.setFieldValue("taxPercentage", newTax);
              }}
              label={t`Davek`}
              endContent="%"
              type="number"
            ></LimeInput>

            <LimeInput
              {...form.getInputProps("discountPercentage")}
              label={t`Popust`}
              type="number"
              endContent="%"
            ></LimeInput>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" color="primary">
              <Trans>Shrani</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
