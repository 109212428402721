import { Textarea, TextAreaProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { isMobileSafari } from "react-device-detect";
import { cn } from "@/utils";

type TextArea = MantineFormValues & TextAreaProps;

export const LimeTextarea = React.forwardRef(
  ({ error, ...props }: TextArea, ref: React.Ref<HTMLTextAreaElement>) => (
    <Textarea
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
    />
  ),
);
LimeTextarea.displayName = "LimeTextarea";
