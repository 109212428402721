import { t } from "@lingui/core/macro";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import { apiRemoveCustomerFromPreScheduledAppointment } from "../../pages/Dashboard/Calendar/api/apiCalendar";
import { useCalendarStore } from "../../pages/Dashboard/Calendar/store/useCalendarStore";
import { useAppointmentModalStore } from "./useAppointmentModalStore_";
import { user } from "@heroui/react";

const useAppointmentModal = (appointmentId) => {
  const { services, selectedAppointmentId } = useCalendarStore(
    (state) => state,
  );

  const {
    setAppointmentId,
    isFetchingData,
    appointmentData,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    selectedUserAppointment,
    setSelectedUserAppointment,
    modifyAppointmentData,
    handleConfirmServiceSelection,
    handleSubmitAppointmentForm,
    fetchAppointmentData,
  } = useAppointmentModalStore((state) => state);

  useEffect(() => {
    setAppointmentId(appointmentId);
  }, [appointmentId, setAppointmentId]);
  // TODO: check za optimizatione za to pa endTime calculation v utils.js
  useEffect(() => {
    // update appointmentData duration as sum of selected services
    if (
      appointmentData &&
      appointmentData.appointmentId == null &&
      selectedServices.length > 0
    ) {
      const newDuration = selectedServices.reduce(
        (acc, ser) =>
          acc +
          services.find((service) => service.serviceId === ser.serviceId)
            .duration *
            1,
        0,
      );

      modifyAppointmentData({
        duration: newDuration,
      });
    }
  }, [selectedServices]);

  const handleStartTimeChange = (startTime) => {
    if (!appointmentData || !startTime) return;

    const splitTime = startTime.split(":");

    const dayObject = dayjs(appointmentData.startTime);
    const newStartTime = dayObject
      .hour(splitTime[0])
      .minute(splitTime[1])
      .second(0)
      .millisecond(0)
      .format("YYYY-MM-DDTHH:mm");

    modifyAppointmentData({
      startTime: newStartTime,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  const handleEndTimeChange = (endTime) => {
    if (!appointmentData || !endTime) return;

    // calculate new duration based on appointmentData.startTime and the new endTime
    const startTimeObject = dayjs(appointmentData.startTime);
    const endTimeObject = dayjs(appointmentData.startTime)
      .hour(endTime.split(":")[0])
      .minute(endTime.split(":")[1]);

    const newDuration = endTimeObject.diff(startTimeObject, "minute");

    modifyAppointmentData({
      duration: newDuration,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  const handleUserAppointmentSelect = (userAppointmentId) => {
    if (selectedUserAppointment === userAppointmentId) {
      setSelectedUserAppointment(null);
    } else {
      setSelectedUserAppointment(userAppointmentId);

      console.log("Selected user appointment", selectedUserAppointment);

      const userAppointment = appointmentData.customers.find(
        (userAppt) => userAppt.userAppointmentId === userAppointmentId,
      );
      if (userAppointment) {
        modifyAppointmentData({
          gsm: userAppointment.gsm,
          email: userAppointment.email,
          name: userAppointment.name,
        });
      }
    }
  };

  const handleDateChange = (date) => {
    if (!appointmentData || !date) return;

    const dayObject = dayjs(appointmentData.startTime);
    const newStartTime = dayObject
      .year(date.getFullYear())
      .month(date.getMonth())
      .date(date.getDate())
      .hour(dayObject.hour())
      .minute(dayObject.minute())
      .second(0)
      .millisecond(0)
      .format("YYYY-MM-DD HH:mm");

    modifyAppointmentData({
      startTime: newStartTime,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  return {
    appointmentData,
    isFetchingData,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    services,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    handleConfirmServiceSelection,
    selectedUserAppointment,
    setSelectedUserAppointment,
    handleUserAppointmentSelect,
    handleSubmitAppointmentForm,
    fetchAppointmentData,
  };
};

export default useAppointmentModal;
