import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { useEffect, useState } from "react";

export const ProductStock = () => {
  const [selectedLocationId, setSelectedLocationId] = useState<null | number>(
    null,
  );

  // API
  const { data: locations, isFetching: areLocationLoading } =
    api.location.useGetLocations({
      sortBy: "labelAsc",
    });

  const { data: productStocks, isFetching: areStocksLoading } =
    api.product.useGetProductStocks({
      locationId: selectedLocationId,
    });

  const { data: products, isFetching: areProductsLoading } =
    api.product.useGetProducts({});

  useEffect(() => {
    if (!selectedLocationId && locations?.locations.length) {
      setSelectedLocationId(locations.locations[0].locationId);
    }
  }, [locations, selectedLocationId]);

  return (
    <>
      <LimePageHeader title={t`Zaloga`}></LimePageHeader>

      <div className="flex flex-col gap-4 px-4 py-4 md:px-8">
        <LimeSelect
          label={t`Lokacija`}
          items={
            locations?.locations.map((l) => ({
              label: l.label,
              key: l.locationId.toString(),
            })) ?? []
          }
          selectedKeys={selectedLocationId?.toString()}
          onChange={(value: string) => setSelectedLocationId(Number(value))}
          isLoading={areLocationLoading}
        ></LimeSelect>

        {productStocks && products && (
          <Table removeWrapper>
            <TableHeader>
              <TableColumn>
                <Trans>Produkt</Trans>
              </TableColumn>
              <TableColumn>
                <Trans>Trenutna zaloga</Trans>
              </TableColumn>
            </TableHeader>
            <TableBody>
              {productStocks.map((ps) => {
                const name = products.products.find(
                  (p) => p.id === ps.productId,
                )?.name;
                return (
                  <TableRow>
                    <TableCell>{name}</TableCell>
                    <TableCell>{ps.quantity}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    </>
  );
};
