import { Menu, Button, Flex } from "@mantine/core";
import { FaAngleDown } from "react-icons/fa";
import { NoShowModal } from "./NoShowModal";
import { AppointmentCancelModal } from "./AppointmentCancelModal";
import { useDisclosure } from "@mantine/hooks";
import { GetAppointment } from "@/server-types";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAppointmentModalStore } from "@/Components/AppointmentModal/useAppointmentModalStore_";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { api } from "@/lib/api-client";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";

const getAppointmentState = (appointment: Appointment) => {
  const currentTime = dayjs();
  const startTime = dayjs(appointment.startTime);
  const totalDuration = appointment.appointmentParts.reduce(
    (total, part) => total + part.duration,
    0,
  );
  const endTime = startTime.add(totalDuration, "minute");

  if (
    appointment.customers.length === 1 &&
    appointment.customers[0].customerDidAttend === -1
  ) {
    return { title: t`Neprihod`, color: "orange" };
  }
  if (currentTime.isBefore(startTime)) {
    return { title: t`Rezerviran`, color: "green" };
  }
  if (currentTime.isAfter(endTime)) {
    return { title: t`Zaključen`, color: "blue" };
  }
  return { title: t`V teku`, color: "teal" };
};

type CustomerAppointments = {
  customers: {
    name: string;
    lastName: string;
    userAppointmentId: number;
    customerDidAttend: number;
  }[];
};

export type Appointment = GetAppointment["response"]["appointment"] &
  CustomerAppointments;

const AppointmentActionsDropdown = ({
  appointment,
  selectedAppointmentId,
}: {
  appointment: Appointment;
  selectedAppointmentId: number;
}) => {
  useLingui();
  const [
    isNoShowModalOpen,
    { open: openNoShowModal, close: closeNoShowModal },
  ] = useDisclosure(false);
  const [
    isCancelModalOpen,
    { open: openCancelModal, close: closeCancelModal },
  ] = useDisclosure(false);

  const appointmentState = getAppointmentState(appointment);

  const { fetchAppointmentData: refreshAppointmentData } =
    useAppointmentModalStore((state) => state);

  const { mutateAsync: updateCustomerAppointments } =
    api.customerAppointment.useUpdateCustomerAppointmentsAttendance();

  const handleUpdateCustomerAppointments = async (
    customerAppointments: {
      userAppointmentId: number;
      customerDidAttend: number;
    }[],
  ) => {
    try {
      await updateCustomerAppointments({
        body: {
          updates: customerAppointments,
        },
      });

      notifications.show({
        title: t`Uspešno`,
        message: t`Prisotnost je bila uspešno posodobljena.`,
        color: "green",
      });
    } catch (error) {
      notifications.show({
        title: t`Napaka`,
        message: t`Prisotnosti ni bilo mogoče posodobiti. Poskusite znova.`,
        color: "red",
      });
    }
    refreshAppointmentData();
    useCalendarStore.getState().refreshCalendar();
  };

  return (
    <>
      <Menu shadow="md" radius={"lg"} width={200}>
        <Menu.Target>
          <Button variant="outline" radius="lg" color={appointmentState.color}>
            <Flex align="center" gap={6}>
              <Trans>{appointmentState.title}</Trans>
              <FaAngleDown
                style={{
                  marginTop: 2,
                }}
              />
            </Flex>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            color="orange"
            onClick={() =>
              appointment.isPreScheduled
                ? openNoShowModal()
                : handleUpdateCustomerAppointments([
                    {
                      userAppointmentId:
                        appointment.customers[0].userAppointmentId,
                      customerDidAttend:
                        appointment.customers[0].customerDidAttend === -1
                          ? 1
                          : -1,
                    },
                  ])
            }
          >
            {appointment.isPreScheduled ? (
              <Trans>Označi prisotnost</Trans>
            ) : (
              <Trans>
                {appointment.customers[0].customerDidAttend === -1
                  ? t`Razveljavi neprihod`
                  : t`Neprihod`}
              </Trans>
            )}
          </Menu.Item>
          <Menu.Item color="red" onClick={openCancelModal}>
            <Trans>Odpoved termina</Trans>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      {appointment.isPreScheduled && (
        <NoShowModal
          handleUpdateCustomerAppointments={handleUpdateCustomerAppointments}
          isOpen={isNoShowModalOpen}
          onClose={closeNoShowModal}
          appointment={appointment}
        />
      )}

      <AppointmentCancelModal
        isOpen={isCancelModalOpen}
        onClose={closeCancelModal}
        appointmentId={selectedAppointmentId}
        isRepeating={appointment.repeatingAppointment}
        isLinked={appointment.linkedAppointment}
      />
    </>
  );
};

export default AppointmentActionsDropdown;
