import { api } from "@/lib/api-client";
import { Avatar, Flex, Table } from "@mantine/core";
import { useState } from "react";
import { ImageUploadView } from "../ImageUploadView";
import { Snippet } from "@heroui/react";

export const LocationsTab = ({ clientId }: { clientId: number }) => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { data: locationsData, refetch } = api.location.useGetLocations({
    clientId,
    page: 1,
    perPage: 10,
    sortBy: "labelAsc",
  });

  const { mutateAsync: uploadImage, isPending: isUploadingImage } =
    api.admin.useUploadLocationImage();

  const { mutateAsync: deleteImage, isPending: isDeletingImage } =
    api.admin.useDeleteLocationImage();

  return (
    <>
      {selectedId ? (
        <ImageUploadView
          close={() => setSelectedId(null)}
          existingImage={
            locationsData?.locations.find(
              (location) => location.locationId === selectedId,
            )?.fullImageUrl
          }
          onUploadImage={async (formData) => {
            await uploadImage({
              body: formData,
              params: { clientId, locationId: selectedId },
            });

            setSelectedId(null);
            refetch();
          }}
          isUploadingImage={isUploadingImage}
          onDeleteImage={async () => {
            await deleteImage({
              params: { clientId, locationId: selectedId },
            });

            setSelectedId(null);
            refetch();
          }}
          isDeletingImage={isDeletingImage}
          defaultCenterCrop={true}
          aspect={1 / 1}
        />
      ) : (
        <Flex direction={"column"}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th w="15ch">ID</Table.Th>
                <Table.Th>Ime</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {locationsData?.locations.map((l) => (
                <Table.Tr
                  key={l.locationId}
                  onClick={() => setSelectedId(l.locationId)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>
                    {l.fullImageUrl != null && (
                      <Avatar src={l.fullImageUrl}></Avatar>
                    )}
                  </Table.Td>
                  <Table.Td>
                    <Snippet symbol="" variant="bordered" className="border-0">
                      {l.locationId}
                    </Snippet>
                  </Table.Td>
                  <Table.Td>{l.label}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};
