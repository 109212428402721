import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { GetOrganizationElectronicDevice } from "@/server-types";
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@heroui/react";
import { Edit, EllipsisVertical } from "lucide-react";

export const ElectronicDeviceCard = ({
  electronicDevice,
  handleEditClick,
}: {
  electronicDevice: GetOrganizationElectronicDevice["response"][number];
  handleEditClick: () => void;
}) => {
  const iconClasses =
    "text-xl text-default-500 pointer-events-none flex-shrink-0";

  return (
    <Card className="mb-4 w-full" shadow="sm">
      <CardBody>
        <div className="flex w-full justify-between">
          <p className="font-semibold text-gray-400">
            {electronicDevice.electronicDeviceId}
          </p>

          {/* <Dropdown isDisabled={isDeletingElectronicDevice}>
            <DropdownTrigger>
              <Button isIconOnly size="sm" variant="light">
                <EllipsisVertical />
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label={t`Akcije za elektronsko napravo`}
              variant="faded"
              onAction={async (key) => {
                switch (key) {
                  case "edit":
                    handleEditClick();
                    break;
                  default:
                    break;
                }
              }}
            >
              <DropdownItem
                key={"edit"}
                startContent={<Edit className={iconClasses} />}
              >
                <Trans>Uredi</Trans>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
        <p className="mb-4 font-semibold">
          {electronicDevice.businessPremiseLabel}
        </p>
      </CardBody>
    </Card>
  );
};
