import { Trans } from "@lingui/react/macro";
import { ActionIcon, Flex, Text, Badge, Menu } from "@mantine/core";
import { MoreVertical } from "lucide-react";
import { useDisclosure } from "@mantine/hooks";
import RemoveCustomerFromAppointmentModal from "./RemoveCustomerFromAppointmentModal";
import { useState } from "react";

type Customer = {
  name: string;
  lastName: string;
  userAppointmentId: number;
  customerId: number;
  customerDidAttend: number;
};
export const CustomerList = ({
  customers,
  selectedUserAppointment,
  handleUserAppointmentSelect,
  openCustomerCard,
  IS_GLOWUP_USER = false,
  appointmentId,
  fetchAppointmentData,
}: {
  appointmentId: number;
  customers: Customer[];
  handleUserAppointmentSelect: (userAppointmentId: number) => void;
  selectedUserAppointment: number;
  openCustomerCard: (customerId: number) => void;
  IS_GLOWUP_USER?: boolean;
  fetchAppointmentData: (appointmentId: number) => void;
}) => {
  // Track which userAppointmentId we want to remove
  const [activeUserAppointmentId, setActiveUserAppointmentId] = useState<
    number | null
  >(null);

  const [
    isRemoveCustomerFromAppointmentModalOpen,
    {
      open: openRemoveCustomerFromAppointmentModal,
      close: closeRemoveCustomerFromAppointmentModal,
    },
  ] = useDisclosure(false);

  const handleOpenRemoveModal = (userAppointmentId: number) => {
    setActiveUserAppointmentId(userAppointmentId);
    openRemoveCustomerFromAppointmentModal();
  };

  return (
    <div className="flex-1">
      {activeUserAppointmentId && (
        <RemoveCustomerFromAppointmentModal
          isOpen={isRemoveCustomerFromAppointmentModalOpen}
          onClose={() => {
            closeRemoveCustomerFromAppointmentModal();
            fetchAppointmentData(appointmentId);
          }}
          appointmentId={appointmentId}
          userAppointmentId={activeUserAppointmentId}
        />
      )}

      {customers.map((customer) => (
        <Flex
          my="md"
          w="100%"
          justify="space-between"
          key={customer.customerId}
          p="sm"
          style={{
            borderRadius: "0.5rem",
            border:
              customer.userAppointmentId === selectedUserAppointment
                ? "1px solid black"
                : "1px solid transparent",
            transition: "border 0.3s ease",
          }}
        >
          <Flex align={"center"} gap={"xs"}>
            <button
              onClick={() => {
                handleUserAppointmentSelect(customer.userAppointmentId);
              }}
            >
              <Text>
                {customer.name} {customer.lastName}
              </Text>
            </button>
            <Badge
              color={customer.customerDidAttend === 1 ? "green" : "red"}
              size="sm"
              variant="outline"
            >
              {customer.customerDidAttend === 1 ? (
                <Trans>Prisoten</Trans>
              ) : (
                <Trans>Odsoten</Trans>
              )}
            </Badge>
          </Flex>
          <Flex justify={"end"} gap={"xs"}>
            <Menu shadow="md">
              <Menu.Target>
                <ActionIcon>
                  <MoreVertical size={16} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {!IS_GLOWUP_USER && (
                  <Menu.Item
                    onClick={() => openCustomerCard(customer.customerId)}
                  >
                    <Text size="sm">
                      <Trans>Kartica stranke</Trans>
                    </Text>
                  </Menu.Item>
                )}

                <Menu.Item
                  color="red"
                  onClick={() => {
                    handleOpenRemoveModal(customer.userAppointmentId);
                  }}
                >
                  <Text size="sm">Odstrani stranko s termina</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
      ))}
    </div>
  );
};
