import { t } from "@lingui/core/macro";
import { api } from "./lib/api-client";
import { useEffect, useRef } from "react";
import { toast } from "sonner";
import * as Sentry from "@sentry/react";
import { useCalendarStore } from "./pages/Dashboard/Calendar/store/useCalendarStore";

/**
 * Constants for the forced reload timeline:
 * - FORCED_RELOAD_DELAY_MS: 2 minutes total from detection to reload.
 * - FIRST_TOAST_OFFSET_MS: First toast appears 1 minute before reload.
 * - SECOND_TOAST_OFFSET_MS: Second toast appears 5 seconds before reload.
 */
const FORCED_RELOAD_DELAY_MS = 6 * 60 * 1000; // 2 minutes
const FIRST_TOAST_OFFSET_MS = 2 * 60 * 1000; // 2 minute before reload
const SECOND_TOAST_OFFSET_MS = 5 * 1000; // 5 seconds before reload

/**
 * A helper to schedule timeouts while logging them and keeping track so they can be cleared.
 */
function scheduleTimeout(
  callback: () => void,
  delayMs: number,
  timeoutsRef: React.MutableRefObject<NodeJS.Timeout[]>,
  label?: string,
) {
  if (label) {
    console.log(
      `[AppVersionChecker] Scheduling timeout (${label}): ${delayMs}ms`,
    );
  }
  const timeoutId = setTimeout(callback, delayMs);
  timeoutsRef.current.push(timeoutId);
}

export const AppVersionChecker = ({ debug }: { debug?: boolean }) => {
  // Get the server version info using your API hook.
  const {
    data: serverAppVersionData,
    error: serverAppVersionError,
    isSuccess: isServerAppVersionSuccess,
    dataUpdatedAt,
  } = api.app.useGetAppVersion();

  // Get the current state of the appointment modal.
  const selectedAppointmentId = useCalendarStore(
    (state) => state.selectedAppointmentId,
  );
  const newBreakData = useCalendarStore((state) => state.newBreakData);
  const isAppointmentModalOpen =
    selectedAppointmentId != null || newBreakData != null;

  // Refs for tracking timeouts, a countdown interval, and modal open status.
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const modalOpenRef = useRef(isAppointmentModalOpen);

  // Update the modal open ref whenever the modal state changes.
  useEffect(() => {
    modalOpenRef.current = isAppointmentModalOpen;
  }, [isAppointmentModalOpen]);

  /**
   * Clears all scheduled timeouts and any active countdown interval.
   */
  const clearAllTimeouts = () => {
    if (debug) console.log("[AppVersionChecker] Clearing all timeouts.");

    timeoutsRef.current.forEach((id) => clearTimeout(id));
    timeoutsRef.current = [];
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }
  };

  /**
   * Clears localStorage markers used to track version detection.
   */
  const clearLocalStorageMarkers = () => {
    if (debug)
      console.log("[AppVersionChecker] Clearing localStorage markers.");

    localStorage.removeItem("newVersionDetectedTime");
    localStorage.removeItem("forcedReloadTime");
  };

  /**
   * attemptForceReload checks if the appointment modal is open.
   * - If it is, it logs a message and reschedules itself to check again in 1 second.
   * - Once the modal is closed, it clears the localStorage markers and reloads the page.
   */
  const attemptForceReload = () => {
    if (debug) console.log("[AppVersionChecker] Attempting force reload...");

    if (modalOpenRef.current) {
      if (debug) {
        console.log(
          "[AppVersionChecker] Appointment modal is open, delaying reload.",
        );
      }

      scheduleTimeout(attemptForceReload, 1000, timeoutsRef, "Delayed Reload");
    } else {
      if (debug) {
        console.log(
          "[AppVersionChecker] Appointment modal closed. Proceeding with reload.",
        );
      }

      clearLocalStorageMarkers();
      window.location.reload();
    }
  };

  /**
   * scheduleToastsAndReload schedules:
   * - A first toast 1 minute before the forced reload.
   * - A second toast 5 seconds before the forced reload.
   * - The forced reload itself.
   * It also sets up a countdown logger that logs the remaining time every second.
   */
  const scheduleToastsAndReload = (forcedReloadTime: number) => {
    const now = Date.now();
    const msUntilReload = Math.max(forcedReloadTime - now, 0);

    if (debug) {
      console.log(
        `[AppVersionChecker] Scheduling events. msUntilReload: ${msUntilReload}ms`,
      );
    }

    // Set up a countdown logging interval.
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    countdownIntervalRef.current = setInterval(() => {
      const remaining = Math.max(forcedReloadTime - Date.now(), 0);

      if (debug) {
        console.log(
          `[Countdown] ${Math.ceil(remaining / 1000)} seconds until forced reload.`,
        );
      }

      // Log when the first toast will appear.
      const timeUntilFirstToast = remaining - FIRST_TOAST_OFFSET_MS;
      if (debug && timeUntilFirstToast > 0) {
        console.log(
          `[Countdown] First toast in ${Math.ceil(timeUntilFirstToast / 1000)} seconds.`,
        );
      }
      // Log when the second toast will appear.
      const timeUntilSecondToast = remaining - SECOND_TOAST_OFFSET_MS;
      if (debug && timeUntilSecondToast > 0) {
        console.log(
          `[Countdown] Second toast in ${Math.ceil(timeUntilSecondToast / 1000)} seconds.`,
        );
      }
      if (remaining <= 0) {
        if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
          countdownIntervalRef.current = null;
        }
      }
    }, 1000);

    // First toast: show 1 minute before reload.
    const firstToastDelay = msUntilReload - FIRST_TOAST_OFFSET_MS;
    if (firstToastDelay > 0) {
      scheduleTimeout(
        () => {
          if (debug) console.log("[AppVersionChecker] Triggering first toast.");

          toast.info(
            t`Nova različica aplikacije je na voljo. Aplikacija se bo samodejno osvežila čez 2 minuti...`,
            {
              position: "top-left",
              dismissible: true,
              duration: 5000,
            },
          );
        },
        firstToastDelay,
        timeoutsRef,
        "First Toast",
      );
    }

    // Second toast: show 5 seconds before reload.
    const secondToastDelay = msUntilReload - SECOND_TOAST_OFFSET_MS;
    if (secondToastDelay > 0) {
      scheduleTimeout(
        () => {
          if (debug)
            console.log("[AppVersionChecker] Triggering second toast.");

          toast.warning(
            t`Nova različica pripravljena! Stran se bo samodejno osvežila čez 5 sekund...`,
            {
              position: "top-left",
              dismissible: false,
            },
          );
        },
        secondToastDelay,
        timeoutsRef,
        "Second Toast",
      );
    }

    // Finally, schedule the force reload.
    scheduleTimeout(
      attemptForceReload,
      msUntilReload,
      timeoutsRef,
      "Force Reload",
    );
  };

  /**
   * checkVersion compares the client version (from an environment variable) with
   * the server version (from our API). If a mismatch is found, we mark the detection time
   * and schedule the toasts and reload. If the component is re-mounted and no timeouts are scheduled,
   * it will re-read the forced reload time from localStorage and schedule the events.
   *
   * If a version mismatch is no longer detected (perhaps because the client was updated)
   * we clear any markers/timeouts.
   */
  const checkVersion = () => {
    if (debug) console.log("[AppVersionChecker] Checking app version...");

    if (serverAppVersionError || !isServerAppVersionSuccess) {
      if (serverAppVersionError) {
        console.error(
          "[AppVersionChecker] Server version error:",
          serverAppVersionError,
        );
        Sentry.captureException(serverAppVersionError);
      }
      return;
    }

    const clientVersion = import.meta.env.VITE_APP_VERSION;
    const serverVersion = serverAppVersionData.version;

    if (debug) {
      console.log(
        `[AppVersionChecker] Client version: ${clientVersion}, Server version: ${serverVersion}`,
      );
    }

    if (serverVersion !== clientVersion) {
      if (debug) {
        console.log("[AppVersionChecker] Version mismatch detected!");
      }

      let detectionTime = localStorage.getItem("newVersionDetectedTime");
      let forcedReloadTime = localStorage.getItem("forcedReloadTime");

      // On first detection, store the current time and calculate the reload time.
      if (!detectionTime) {
        const now = Date.now();
        detectionTime = now.toString();
        forcedReloadTime = (now + FORCED_RELOAD_DELAY_MS).toString();
        localStorage.setItem("newVersionDetectedTime", detectionTime);
        localStorage.setItem("forcedReloadTime", forcedReloadTime);

        if (debug) {
          console.log(
            "[AppVersionChecker] Set new version detection time and forced reload time.",
          );
        }
      }

      const reloadTime = forcedReloadTime
        ? parseInt(forcedReloadTime, 10)
        : Date.now() + FORCED_RELOAD_DELAY_MS;

      if (debug)
        console.log(`[AppVersionChecker] Calculated reloadTime: ${reloadTime}`);

      // If our local timeouts aren't scheduled (for example, after a re-mount),
      // then schedule the toasts and reload events.
      if (timeoutsRef.current.length === 0) {
        if (debug) {
          console.log(
            "[AppVersionChecker] No scheduled events found. Scheduling toasts and reload events.",
          );
        }

        scheduleToastsAndReload(reloadTime);
      } else {
        if (debug) {
          console.log(
            "[AppVersionChecker] Toasts and reload already scheduled.",
          );
        }
      }
    } else {
      // Versions match – clear any scheduled reload and localStorage markers.
      if (localStorage.getItem("newVersionDetectedTime")) {
        if (debug) {
          console.log(
            "[AppVersionChecker] Versions match again. Clearing scheduled events.",
          );
        }

        clearAllTimeouts();
        clearLocalStorageMarkers();
      }
    }
  };

  // Re-run checkVersion whenever the server version data is updated.
  useEffect(() => {
    checkVersion();
    return () => clearAllTimeouts();
  }, [dataUpdatedAt, serverAppVersionData]);

  return null;
};
