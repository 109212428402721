import useAlertModal from "@/Components/Modals/useAlertModal";
import { api } from "@/lib/api-client";
import { hexToRgba, rgbaStringToHex } from "@/utils";
import { ActionIcon, Flex, Group, Tabs } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { IoMdArrowBack } from "react-icons/io";
import { TiTrash } from "react-icons/ti";
import type {
  DetailField,
  PutClientForm,
} from "../../../../../../../../server/src/types";
import { ImageUploadView } from "../ImageUploadView";
import { CustomFields } from "./CustomFields";
import { GeneralData } from "./GeneralData";
import { Slider } from "@heroui/react";

export const FormDetails = ({
  clientId,
  formId,
  close,
}: {
  clientId: number;
  formId: number;
  close: () => void;
}) => {
  const { data } = api.admin.useClientForm({ clientId, formId });

  const { mutateAsync: updateForm } = api.admin.useUpdateClientForm();

  const { mutateAsync: deleteForm } = api.admin.useDeleteClientForm();

  const { alertModal } = useAlertModal();

  const [newData, setNewData] = useState<
    | (PutClientForm["body"] & {
        details: DetailField[];
        backgroundImageUrl: string | null;
      })
    | null
  >(null);

  const { mutateAsync: uploadImage, isPending: isUploadingImage } =
    api.admin.useUploadFormBackgroundImage();

  const { mutateAsync: deleteImage, isPending: isDeletingImage } =
    api.admin.useDeleteFormBackgroundImage();

  useEffect(() => {
    if (!data || newData) return;

    const details = data.form.details as DetailField[];
    const nameDetailConfig = details.find((detail) => detail.id === "name");
    const lastNameDetailConfig = details.find(
      (detail) => detail.id === "lastName",
    );
    const emailDetailConfig = details.find((detail) => detail.id === "email");
    const gsmDetailConfig = details.find((detail) => detail.id === "gsm");

    console.log("DB DETAILS", details);

    const defaultDetails: DetailField[] = [
      {
        id: "name",
        type: "text",
        label: {
          en: "Name",
          sl: "Ime",
          hr: "Ime",
          sr: "Ime",
          it: "Nome",
          de: "Name",
        },
        required: nameDetailConfig?.required ?? true,
      },
      {
        id: "lastName",
        type: "text",
        label: {
          en: "Last Name",
          sl: "Priimek",
          hr: "Prezime",
          sr: "Ime",
          it: "Cognome",
          de: "Nachname",
        },
        required: lastNameDetailConfig?.required ?? true,
      },
      {
        id: "email",
        type: "text",
        label: {
          en: "Email",
          sl: "Elektronski naslov",
          hr: "Email",
          sr: "Email",
          it: "Email",
          de: "Email",
        },
        required: emailDetailConfig?.required ?? false,
      },
      {
        id: "gsm",
        type: "text",
        label: {
          en: "Phone number",
          sl: "Telefonska številka",
          hr: "Broj mobitela",
          sr: "Broj mobitela",
          it: "Numero di telefono",
          de: "Telefonnummer",
        },
        required: gsmDetailConfig?.required ?? false,
      },
    ];

    const defaultDetailIds = ["name", "lastName", "email", "gsm"];
    const extraDetails = details.filter(
      (detail) => !defaultDetailIds.includes(detail.id),
    );

    const parsedDetails: DetailField[] = [
      ...defaultDetails,
      ...extraDetails
        .filter((detail) => !defaultDetailIds.includes(detail.id))
        .map((detail) => {
          return {
            ...detail,
            label: {
              ...detail.label,
            },
          };
        }),
    ];

    setNewData({
      ...data.form,
      details: parsedDetails,
      colorLighter: data.form.colorLighter
        ? hexToRgba(data.form.colorLighter)
        : null,
      formBackgroundOpacity: data.form.formBackgroundOpacity
        ? Number(data.form.formBackgroundOpacity)
        : undefined,
    });
    // setNewSteps(data.form.form_step);
  }, [data, setNewData, newData]);

  const handleUpdate = async () => {
    if (!newData || !newData.hash) return;

    try {
      console.log("UPDATING WITH DETAILS", newData.details);
      await updateForm({
        body: {
          ...newData,
          colorLighter: newData.colorLighter
            ? rgbaStringToHex(newData.colorLighter)
            : null,
          details: newData.details,
        },
        params: { clientId, formId },
      });

      notifications.show({
        message: "Podatki uspešno posodobljeni",
        color: "green",
      });
      close();
    } catch (e) {
      notifications.show({
        message: "Napaka pri posodabljanju podatkov",
        color: "red",
      });
      console.log(e);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteForm({ clientId, formId });

      notifications.show({
        message: "Forma uspešno izbrisana",
        color: "green",
      });
      close();
    } catch (e) {
      notifications.show({
        message: "Napaka pri brisanju forme",
        color: "red",
      });
      console.log(e);
    }
  };

  return (
    <Flex direction={"column"} gap={20} justify={"flex-start"}>
      <Group mt={20}>
        <ActionIcon onClick={close}>
          <IoMdArrowBack size="24px"></IoMdArrowBack>
        </ActionIcon>
        <ActionIcon onClick={handleUpdate}>
          <FiSave size="24px"></FiSave>
        </ActionIcon>
        <ActionIcon
          onClick={() => {
            alertModal(
              "Brisanje forme",
              <></>,
              { labelCancel: "Prekliči", labelConfirm: "Izbriši" },
              handleDelete,
            );
          }}
        >
          <TiTrash size="24px"></TiTrash>
        </ActionIcon>
      </Group>
      <Tabs defaultValue={"general"}>
        <Tabs.List>
          <Tabs.Tab value="general">Podatki</Tabs.Tab>
          <Tabs.Tab value="custom-fields">Custom fields</Tabs.Tab>
          <Tabs.Tab value="background-image">Background image</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="general">
          {data && newData ? (
            <GeneralData data={newData} setData={setNewData}></GeneralData>
          ) : (
            <></>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="custom-fields">
          {data && newData ? (
            <CustomFields
              details={newData.details}
              setDetails={(details) => {
                console.log("DETAILS", details);

                setNewData({
                  ...newData,
                  details,
                });
              }}
            ></CustomFields>
          ) : (
            <></>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="background-image">
          <ImageUploadView
            close={() => {}}
            isUploadingImage={isUploadingImage}
            onUploadImage={async (formData) => {
              const response = await uploadImage({
                body: formData,
                params: { clientId, formId },
              });
              console.log("response", response);

              notifications.show({
                message: "Slika uspešno posodobljena",
              });

              close();
            }}
            onDeleteImage={async () => {
              await deleteImage({
                params: {
                  clientId,
                  formId,
                },
              });

              notifications.show({
                message: "Slika uspešno izbrisana",
              });

              close();
            }}
            isDeletingImage={isDeletingImage}
            existingImage={newData?.backgroundImageUrl}
          ></ImageUploadView>
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
};
