import { AlertButton } from "@/Components/NextBase/AlertButton";
import { TextVariant } from "@/types/text-variants";
import { Button, Flex, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiUpload2Fill } from "react-icons/ri";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type PixelCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export const ImageUploadView = ({
  existingImage,
  close,
  onUploadImage,
  isUploadingImage,
  onDeleteImage,
  isDeletingImage,
  circularCrop,
  defaultCenterCrop,
  aspect,
}: {
  existingImage?: string | null;
  close: () => void;
  onUploadImage: (formData: FormData) => void;
  isUploadingImage?: boolean;
  onDeleteImage?: () => void;
  isDeletingImage?: boolean;
  circularCrop?: boolean;
  defaultCenterCrop?: boolean;
  aspect?: number;
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const blobUrlRef = useRef("");
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState<string | null>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      file: null as File | null,
    },

    validate: {
      file: (value) => (value === null ? "File is required" : null),
    },
  });

  const handleUpload = async () => {
    const image = imgRef.current;

    if (!image || !completedCrop) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get 2d context");
    }

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    const formData = new FormData();
    formData.append("image", blob);

    onUploadImage(formData);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    if (defaultCenterCrop) {
      const crop = centerCrop(
        makeAspectCrop(
          {
            // You don't need to pass a complete crop into
            // makeAspectCrop or centerCrop.
            unit: "%",
            width: 50,
          },
          aspect ?? 1 / 1,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop(crop);
    } else {
      setCrop(
        makeAspectCrop(
          {
            // You don't need to pass a complete crop into
            // makeAspectCrop or centerCrop.
            unit: "%",
            width: 100,
            height: 100,
          },
          aspect ?? 1 / 1,
          width,
          height,
        ),
      );
    }
  };

  return (
    <>
      <Button onClick={close}>Zapri</Button>
      <Dropzone
        accept={IMAGE_MIME_TYPE}
        onDrop={(files) => {
          form.setFieldValue("file", files[0]);

          if (files && files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              setImgSrc(reader.result as string);
            });

            reader.readAsDataURL(files[0]);
          }
        }}
        maxFiles={1}
      >
        <Dropzone.Accept>
          <Flex align={"center"} gap={"xs"}>
            <RiUpload2Fill />
            <Text variant={TextVariant.BodyEmphasized}>UPLOAD</Text>
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex align={"center"} gap={"xs"}>
            <AiOutlineClose />
            <Text variant={TextVariant.BodyEmphasized}>
              ONLY IMAGES ALLOWED
            </Text>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex align={"center"} gap={"xs"}>
            <RiUpload2Fill />
            <Text variant={TextVariant.BodyEmphasized}>
              Drag an image or click to choose
            </Text>
          </Flex>
        </Dropzone.Idle>
      </Dropzone>

      {form.getValues().file && (
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          circularCrop={circularCrop}
        >
          <img
            ref={imgRef}
            src={imgSrc ?? ""}
            alt="User"
            onLoad={onImageLoad}
            style={{ maxHeight: "60vh" }}
          />
        </ReactCrop>
      )}

      {form.getValues().file && (
        <Button loading={isUploadingImage} onClick={handleUpload}>
          Shrani
        </Button>
      )}

      {completedCrop && (
        <div
          style={{
            display: "none",
          }}
        >
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        </div>
      )}

      <a
        href="#hidden"
        ref={hiddenAnchorRef}
        download
        style={{
          position: "absolute",
          top: "-200vh",
          visibility: "hidden",
        }}
      >
        Hidden download
      </a>

      {existingImage != null && (
        <>
          <p>Existing image:</p>
          <img src={existingImage} style={{ height: "200px" }} alt="User" />
          <AlertButton
            title="Izbriši sliko"
            content="Ste prepricani, da zelite izbrisati sliko?"
            confirmProps={{
              onClick: async () => onDeleteImage?.(),
              isLoading: isDeletingImage,
            }}
          >
            Izbriši
          </AlertButton>
        </>
      )}
    </>
  );
};
