import { Pagination, PaginationProps } from "@heroui/react";
import { LimeSelect } from "./LimeSelect";

export const LimePagination = ({
  paginationProps,
  perPageProps,
}: {
  paginationProps: PaginationProps;
  perPageProps: {
    label: string;
    onChange?: (value: string) => void;
    value?: string;
    values?: number[];
  };
}) => {
  return (
    <div className="flex w-full flex-col-reverse justify-between gap-2 md:flex-row">
      <Pagination {...paginationProps} showControls />
      {perPageProps.values && perPageProps.values.length > 1 && (
        <LimeSelect
          {...perPageProps}
          label={undefined}
          className="max-w-none md:max-w-44"
          items={perPageProps.values.map((value) => ({
            label: `${value} ${perPageProps.label}`,
            key: String(value),
          }))}
        />
      )}
    </div>
  );
};
