import { RadioGroup, RadioGroupProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";

type RadioGroup = MantineFormValues & RadioGroupProps;

export const LimeRadioGroup = React.forwardRef(
  ({ error, ...props }: RadioGroup, ref: React.Ref<HTMLInputElement>) => (
    <RadioGroup ref={ref} errorMessage={error} isInvalid={!!error} {...props} />
  ),
);
LimeRadioGroup.displayName = "LimeRadioGroup";
