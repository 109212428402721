import { t } from "@lingui/core/macro";
import { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import useAccountInfo from "../../hooks/useAccountInfo";
import useFeature from "../../hooks/useFeature";
import usePreferredLanguageStore from "../../stores/usePreferredLanguageStore";
import { CalendarOnboardingPopup } from "./Calendar/components/CalendarOnboardingPopup";
import { api } from "@/lib/api-client";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import RouteChangeListener from "./RouteChangeListener";
import { toast } from "sonner";
import { DashboardRoutes } from "./DashboardRoutes";

const Dashboard = () => {
  const [isOnboardingSkipped, setIsOnboardingSkipped] = useState(false);

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const { isFeatureEnabled } = useFeature();
  const { data: globalSettings } = api.app.useGetGlobalSettings(
    !isFeatureEnabled("TAX_REGISTER"),
  );

  const { loadPreferredLanguage } = usePreferredLanguageStore((state) => state);
  useEffect(() => {
    loadPreferredLanguage();
  }, []);

  const { setupQzTray } = usePosPrinterStore((state) => state);
  useEffect(() => {
    if (!globalSettings) return;

    const qzTrayClientCert = globalSettings.find(
      (setting) => setting.settingName === "qz_tray_client_crt",
    );
    const qzTrayClientKey = globalSettings.find(
      (setting) => setting.settingName === "qz_tray_client_key",
    );

    if (
      !qzTrayClientCert ||
      !qzTrayClientKey ||
      !qzTrayClientCert.settingValue ||
      !qzTrayClientKey.settingValue
    ) {
      toast.error(t`Povezave s POS tiskalnikom ni bilo mozno vzpostaviti.`);
      return;
    }

    setupQzTray({
      qzTrayClientCert: atob(qzTrayClientCert.settingValue),
      qzTrayClientKey: atob(qzTrayClientKey.settingValue),
    });
  }, [globalSettings]);

  return (
    <>
      <RouteChangeListener />
      <div className="flex min-h-screen bg-[#f2f7f5]">
        <Sidebar />

        {!accountInfo.onboardingDone && !isOnboardingSkipped && (
          <CalendarOnboardingPopup
            handleSkipOnboarding={() => setIsOnboardingSkipped(true)}
          />
        )}

        <DashboardRoutes />
      </div>
    </>
  );
};

export default Dashboard;
