import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { useLingui } from "@lingui/react";
import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Flex,
  Tabs,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useRef } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { BsPersonVcard } from "react-icons/bs";
import { RiArrowLeftLine } from "react-icons/ri";
import { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import AppointmentModal from "../../../../../Components/AppointmentModal/AppointmentModal";
import { useAppointmentModalStore } from "../../../../../Components/AppointmentModal/useAppointmentModalStore_";
import LimeDrawer from "../../../../../Components/LimeDrawer/LimeDrawer";
import useFeature from "../../../../../hooks/useFeature";
import CustomerCard from "../../../Completed/CustomerCard/CustomerCard";
import { useCalendarStore } from "../../store/useCalendarStore";
import BreakModal from "./BreakModal";
import PrescheduledAppointmentModal from "./PrescheduledAppointmentModal";
import { ReceiptText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import AppointmentActionsDropdown from "./AppointmentActionsDropdown/AppointmentActionsDropdown";
import AppointmentLog from "./AppointmentLog/AppointmentLog";

const CalendarModalWrapper = ({
  refetchTimeoffs,
  IS_GLOWUP_USER,
  IS_ILLUME_USER,
}) => {
  const navigate = useNavigate();

  const {
    newBreakData,
    setNewBreakData,
    selectedAppointmentId,
    setSelectedAppointmentId,
    hideModalTabs,
    setHideModalTabs,
    isExpandedModal,
    setIsExpandedModal,
    setNewPreScheduledAppointmentData,
    newPreScheduledAppointmentData,
    closeModals,
  } = useCalendarStore((state) => state);
  useLingui();

  const {
    confirmedServiceSelection,
    handleConfirmServiceSelection,
    setConfirmedServiceSelection,
    selectedServices,
    wirelesslySubmitAppointmentForm,
    appointmentData,
    selectedUserAppointment,
    fetchAppointmentData,
    currentAppointmentGsm,
    isCreatingAppointment,
  } = useAppointmentModalStore((state) => state);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const isExistingAppointmentSelected =
    selectedAppointmentId != null && selectedAppointmentId !== -1;

  const [selectedTab, setSelectedTab] = React.useState(
    permissions.manage_appointments ||
      ![null, -1].includes(selectedAppointmentId)
      ? "appointment"
      : "break",
  );

  const [customerCardCustomerId, setCustomerCardCustomerId] =
    React.useState(null);

  const [isLunchTimeValid, setIsLunchTimeValid] = React.useState(true);

  const isDrawerOpen = selectedAppointmentId != null || newBreakData != null;

  const { isFeatureEnabled } = useFeature();

  useLingui();

  const activeTabRef = useRef(null);

  useEffect(() => {
    if (!isDrawerOpen) return;

    if (selectedAppointmentId != null && permissions.manage_appointments) {
      setSelectedTab("appointment");
    } else if (newBreakData != null) {
      setSelectedTab("break");
    } else if (permissions.manage_appointments) {
      setSelectedTab("scheduled_appointment");
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    setSelectedTab(
      permissions.manage_appointments || isExistingAppointmentSelected
        ? "appointment"
        : "break",
    );
  }, [selectedAppointmentId]);

  let drawerTitle;
  if (selectedTab === "appointment") {
    const isNewAppointment = selectedAppointmentId === -1;

    if (isNewAppointment) {
      drawerTitle = t`Dodajanje termina`;
    } else {
      drawerTitle = t`Urejanje termina`;
    }
  } else if (selectedTab === "customer_card") {
    drawerTitle = "Kartica stranke";
  } else if (selectedTab === "prescheduled-customer-list") {
    drawerTitle = "Stranke termina";
  } else if (selectedTab === "break") {
    drawerTitle = t`Dodajanje pavze`;
  } else if (selectedTab === "appointment_log") {
    drawerTitle = t`Dnevnik sprememb`;
  } else {
    drawerTitle = t`Razpisan termin`;
  }

  const onClose = () => {
    setNewBreakData(null);
    setNewPreScheduledAppointmentData(null);
    setSelectedAppointmentId(null);
    closeModals();
    refetchTimeoffs();

    setTimeout(() => {
      setHideModalTabs(false);
      setIsExpandedModal(false);
    }, 250);
  };

  const openCustomerCard = (customerId) => {
    setCustomerCardCustomerId(customerId);
    setSelectedTab("customer_card");
  };

  let confirmAction = {};
  if (selectedTab === "appointment" && permissions.manage_appointments) {
    if (isExistingAppointmentSelected) {
      if (appointmentData?.isPreScheduled) {
        if (!selectedUserAppointment) {
          confirmAction = {
            text: t`Dodaj osebo`,
            action: () => {
              wirelesslySubmitAppointmentForm();
            },
          };
        } else {
          confirmAction = {
            text: t`Posodobi podatke`,
            action: () => {
              wirelesslySubmitAppointmentForm();
            },
          };
        }
      } else {
        confirmAction = {
          text: t`Posodobi podatke`,
          action: () => {
            wirelesslySubmitAppointmentForm();
          },
        };
      }
    } else {
      if (!confirmedServiceSelection) {
        confirmAction = {
          text: t`Potrdi izbiro`,
          action: () => {
            handleConfirmServiceSelection(true);
          },
          identifier: "confirm-service-selection",
        };

        if (selectedServices.length === 0) {
          confirmAction.disabled = true;
        }
      } else {
        confirmAction = {
          text: t`Dodaj termin`,
          action: () => {
            wirelesslySubmitAppointmentForm();
          },
          identifier: "submit-appointment",
          // disabled if phone number invalid
          disabled:
            (currentAppointmentGsm &&
              !isValidPhoneNumber(currentAppointmentGsm)) ||
            selectedServices.length < 1 ||
            isCreatingAppointment,
        };
      }
    }
  } else if (selectedTab === "break") {
    // manj ko gledas to kodo bols je
    // zdej sm naredu sam se slabs
    confirmAction = {
      text: t`Posodobi pavze`,
      action: () => {
        activeTabRef.current.action(refetchTimeoffs);
      },
      disabled: !isLunchTimeValid,
    };
  } else if (selectedTab === "scheduled_appointment") {
    confirmAction = {
      text: t`Dodaj razpisan termin`,
      action: () => {
        document.getElementById("submit-scheduled-appointment-button").click();
      },
      disabled: !(
        newPreScheduledAppointmentData?.selectedServiceId &&
        (newPreScheduledAppointmentData?.selectedUserId ||
          newPreScheduledAppointmentData?.resources) &&
        newPreScheduledAppointmentData?.startTime &&
        newPreScheduledAppointmentData?.endTime &&
        newPreScheduledAppointmentData?.numberOfPeople
      ),
    };
  }

  const isMobile = useMediaQuery("(max-width: 768px)");

  let drawerSize = "md";
  if (isExpandedModal) drawerSize = "70rem";
  if (selectedTab === "customer_card") drawerSize = "fullScreen";

  return (
    <Wrapper>
      <LimeDrawer
        opened={isDrawerOpen}
        onClose={isDrawerOpen ? onClose : null}
        title={drawerTitle}
        appointmentId={
          selectedAppointmentId === -1 || selectedTab === "customer_card"
            ? undefined
            : selectedAppointmentId
        }
        size={drawerSize}
        buttons={
          selectedTab === "customer_card" ||
          selectedTab === "appointment_log" ||
          selectedTab === "prescheduled-customer-list"
            ? null
            : {
                save:
                  permissions.manage_appointments || selectedTab === "break" ? (
                    <Button
                      data-identifier={"confirmActionButton"}
                      onClick={confirmAction.action}
                      disabled={confirmAction.disabled}
                      data-initializer={confirmAction.identifier ?? undefined}
                      w={"100%"}
                    >
                      {confirmAction.text}
                    </Button>
                  ) : undefined,
                cancel: (
                  <Button w={"100%"} variant="outline" onClick={onClose}>
                    <Trans>Prekliči</Trans>
                  </Button>
                ),
              }
        }
        headerActions={
          <Flex align={"center"} gap={"1rem"}>
            {confirmedServiceSelection && (
              <ActionIcon
                onClick={() => {
                  setConfirmedServiceSelection(false);
                }}
              >
                <RiArrowLeftLine size={"1.5rem"} />
              </ActionIcon>
            )}

            {isExistingAppointmentSelected &&
            permissions.manage_appointments &&
            isFeatureEnabled("TAX_REGISTER") &&
            selectedTab !== "customer_card" &&
            selectedTab !== "appointment_log" ? (
              <ActionIcon
                onClick={() => {
                  const uApptId = selectedUserAppointment;

                  const userId = appointmentData.appointmentParts[0]?.users[0];

                  const customer = appointmentData.customers.find(
                    (customer) => customer.userAppointmentId == uApptId,
                  );

                  if (!uApptId || !customer) return;

                  const existingInvoiceId = customer.invoiceId;

                  if (existingInvoiceId) {
                    // navigate to archive and open this invoice
                    navigate(
                      `/dashboard/tax-register/invoice/${existingInvoiceId}`,
                    );
                  } else {
                    // navigate to POS page
                    navigate(
                      `/dashboard/tax-register/invoice/new?uaid=${uApptId}${userId ? `&auid=${userId}` : ""}`,
                    );
                  }
                }}
                disabled={selectedUserAppointment == null}
              >
                <ReceiptText />
              </ActionIcon>
            ) : undefined}

            {isExistingAppointmentSelected &&
            permissions.manage_appointments &&
            appointmentData &&
            selectedTab !== "customer_card" ? (
              <AppointmentActionsDropdown
                appointment={appointmentData}
                selectedAppointmentId={selectedAppointmentId}
              ></AppointmentActionsDropdown>
            ) : undefined}

            {(selectedTab === "customer_card" ||
              selectedTab === "appointment_log") && (
              <ActionIcon
                onClick={() => {
                  setSelectedTab(
                    appointmentData?.isPreScheduled
                      ? "prescheduled-customer-list"
                      : "appointment",
                  );

                  fetchAppointmentData(selectedAppointmentId);
                }}
              >
                <RiArrowLeftLine size={"1.5rem"} />
              </ActionIcon>
            )}

            <Drawer.CloseButton size={"1.5rem"} style={{ width: "20px" }} />
          </Flex>
        }
      >
        <Tabs
          defaultValue={
            permissions.manage_appointments ? "appointment" : "break"
          }
          value={selectedTab}
          onChange={(value) => setSelectedTab(value)}
          keepMounted={false}
        >
          <Tabs.List
            style={{
              display: selectedTab === "customer_card" ? "none" : "flex",
            }}
          >
            {!(
              hideModalTabs &&
              selectedTab !== "appointment" &&
              selectedTab !== "customer_card" &&
              selectedTab !== "prescheduled-customer-list" &&
              selectedTab !== "appointment_log"
            ) &&
            (permissions.manage_appointments ||
              isExistingAppointmentSelected) ? (
              <>
                <Tabs.Tab value="appointment">
                  <Trans>Termin</Trans>
                </Tabs.Tab>

                {!appointmentData?.isPreScheduled &&
                !IS_GLOWUP_USER &&
                !IS_ILLUME_USER &&
                selectedAppointmentId != null &&
                selectedAppointmentId !== -1 &&
                permissions.view_customer_card_on_calendar ? (
                  <Tabs.Tab
                    value="customer_card"
                    onClick={() => {
                      openCustomerCard(
                        appointmentData?.customers?.at(0)?.customerId,
                      );
                    }}
                  >
                    <Trans>Kartica stranke</Trans>
                  </Tabs.Tab>
                ) : undefined}

                {selectedAppointmentId !== -1 &&
                  isFeatureEnabled("CHANGELOG") && (
                    <Tabs.Tab value="appointment_log">
                      <Trans>Spremembe</Trans>
                    </Tabs.Tab>
                  )}

                {appointmentData?.isPreScheduled &&
                  !IS_GLOWUP_USER &&
                  !IS_ILLUME_USER &&
                  selectedAppointmentId != null &&
                  selectedAppointmentId !== -1 && (
                    <Tabs.Tab value="prescheduled-customer-list">
                      <Trans>Stranke</Trans>
                    </Tabs.Tab>
                  )}
              </>
            ) : null}
            {!(hideModalTabs && selectedTab !== "break") && (
              <Tabs.Tab value="break">
                <Trans>Pavza</Trans>
              </Tabs.Tab>
            )}
            {!(hideModalTabs && selectedTab !== "scheduled_appointment") &&
            isFeatureEnabled("PRESCHEDULED") &&
            (permissions.manage_appointments ||
              isExistingAppointmentSelected) ? (
              <Tabs.Tab
                value="scheduled_appointment"
                data-identifier="prescheduled-appointment-tab"
              >
                <Trans> Razpisan termin</Trans>
              </Tabs.Tab>
            ) : null}
          </Tabs.List>

          {permissions.manage_appointments || isExistingAppointmentSelected ? (
            <Tabs.Panel value="appointment" pt={0}>
              <AppointmentModal
                appointmentId={selectedAppointmentId}
                openCustomerCard={openCustomerCard}
                IS_GLOWUP_USER={IS_GLOWUP_USER}
                IS_ILLUME_USER={IS_ILLUME_USER}
              />
            </Tabs.Panel>
          ) : null}

          <Tabs.Panel value="prescheduled-customer-list" pt={0}>
            <PreScheduledCustomerList>
              {appointmentData?.customers?.map((customer, i) => (
                <div
                  key={i}
                  className="customer"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  <Flex justify={"space-between"} align={"center"}>
                    <div>
                      <Text fw={500} lh={1.2}>
                        {customer?.name} {customer?.lastName}
                      </Text>
                      <Text size={"xs"} lh={1} c="grey">
                        {customer?.gsm}
                      </Text>
                      <Text size={"xs"} lh={1} c="grey">
                        {customer?.email}
                      </Text>
                    </div>
                    <Flex direction={"row"} align={"center"}>
                      <ActionIcon
                        onClick={() => {
                          openCustomerCard(customer?.customerId);
                        }}
                        size={isMobile ? "lg" : "xl"}
                      >
                        <BsPersonVcard size="50%" />
                      </ActionIcon>
                      <Divider
                        orientation="vertical"
                        mx={isMobile ? "xs" : "sm"}
                      />
                      <ActionIcon
                        href={`tel:${customer?.gsm}`}
                        size={isMobile ? "lg" : "xl"}
                        component="a"
                      >
                        <AiOutlinePhone
                          size={"50%"}
                          style={{
                            transform: "scaleX(-1)",
                          }}
                        />
                      </ActionIcon>
                    </Flex>
                  </Flex>
                </div>
              ))}
            </PreScheduledCustomerList>
          </Tabs.Panel>

          <Tabs.Panel value="customer_card" pt={0}>
            {customerCardCustomerId != null ? (
              <CustomerCard
                customerId={customerCardCustomerId}
                handleClose={({ isCustomerDeleted }) => {
                  setCustomerCardCustomerId(null);
                  setSelectedTab("appointment");

                  if (isCustomerDeleted) {
                    onClose();
                  } else {
                    fetchAppointmentData(selectedAppointmentId);
                  }
                }}
                isMobile={window.innerWidth < 768}
              />
            ) : null}
          </Tabs.Panel>

          <Tabs.Panel value="break" pt="xs">
            <BreakModal
              ref={activeTabRef}
              onClose={onClose}
              onLunchTimeValidChange={setIsLunchTimeValid}
            />
          </Tabs.Panel>

          {permissions.manage_appointments ? (
            <Tabs.Panel value="scheduled_appointment" pt="xs">
              <PrescheduledAppointmentModal onClose={onClose} />
            </Tabs.Panel>
          ) : null}

          <Tabs.Panel value="appointment_log" pt="xs">
            <AppointmentLog appointmentId={selectedAppointmentId} />
          </Tabs.Panel>
        </Tabs>
      </LimeDrawer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mantine-Drawer-inner {
    right: 10px;
    margin: 0 10px;
  }
`;

const PreScheduledCustomerList = styled.div`
  display: flex;
  flex-direction: column;

  .customer {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .name {
      font-weight: 600;
    }

    .email {
      font-size: 12px;
      line-height: 1;
    }
  }
`;

export default CalendarModalWrapper;
