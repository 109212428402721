import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeSelect, SingleSelect } from "@/Components/NextBase/LimeSelect";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
  useDisclosure,
} from "@heroui/react";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { useForm } from "@mantine/form";
import { Form } from "./BusinessPremise";
import { api } from "@/lib/api-client";
import { Plus } from "lucide-react";
import { CreateLocationDialog } from "../CreateLocationDialog";
import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const ManageBusinessPremiseModal = ({
  isManageModalOpen,
  onOpenManageModalChange,
  businessPremise_id,
}: {
  isManageModalOpen: boolean;
  onOpenManageModalChange: (id?: string) => void;
  businessPremise_id?: string;
}) => {
  const {
    isOpen: isCreateLocationModalOpen,
    onOpen: onOpenCreateLocationModal,
    onClose: onCloseCreateLocationModal,
  } = useDisclosure();

  const {
    data: locations,
    isFetching: isFetchingLocations,
    refetch: refetchLocations,
  } = api.location.useGetLocations({
    page: 1,
    perPage: 100,
    sortBy: "labelDesc",
  });

  const { data: organization } = api.taxRegister.useGetOrganization({});

  const { data: businessPremises } =
    api.taxRegister.useGetOrganizationBusinessPremise({});

  const {
    mutateAsync: createBusinessPremise,
    isPending: isCreatingBusinessPremise,
    processedErrorMessage: createErrorMessage,
  } = api.taxRegister.usePostOrganizationBusinessPremise();

  const {
    mutateAsync: updateBusinessPremise,
    isPending: isUpdatingBusinessPremise,
    processedErrorMessage: updateErrorMessage,
  } = api.taxRegister.useUpdateBusinessPremise();

  const form = useForm<Form>({
    initialValues: {
      type: "realestate",
      premiseType: null,
      locationId: null,
      startNumberingWith: 1,

      businessPremiseId: "",
      provider: "furs",
      cadastralNumber: 0,
      buildingNumber: 0,
      buildingSectionNumber: 0,
      street: "",
      houseNumber: "",
      houseNumberAdditional: "",
      community: "",
      city: "",
      postalCode: "",
    },

    validate: {
      type: (value: "realestate" | "movable") => {
        if (!value) {
          //   return "Type is required";
          return t`Vrsta poslovnega prostora je obvezna`;
        }
      },

      premiseType: (value: "A" | "B" | "C") => {
        if (form.getValues().type !== "movable") {
          return null;
        }

        if (!value && form.getValues().type === "movable") {
          //   return "Premise type is required";
          return t`Vrsta premičnega prostora je obvezna`;
        }
      },

      postalCode: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Poštna številka je obvezna`;
        }

        if (value.length < 4) {
          return t`Poštna številka mora biti dolga vsaj 4 znake`;
        }
      },

      city: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Mesto je obvezno`;
        }

        if (value.length < 1) {
          return t`Mesto mora biti dolga vsaj 1 znak`;
        }
      },

      street: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Ulica je obvezna`;
        }

        if (value.length < 4) {
          return t`Ulica mora biti dolga vsaj 4 znake`;
        }
      },
      houseNumber: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Številka je obvezna`;
        }
      },
      community: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Kraj je obvezna`;
        }

        if (value.length < 4) {
          return t`Kraj mora biti dolga vsaj 4 znake`;
        }
      },

      cadastralNumber: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Katastrska številka je obvezna`;
        }
      },

      buildingNumber: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Številka stavbe je obvezna`;
        }
      },

      buildingSectionNumber: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Številka sekcije je obvezna`;
        }
      },

      locationId: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Lokacija je obvezna`;
        }
      },

      businessPremiseId: (value) => {
        if (form.getValues().type !== "realestate") {
          return null;
        }

        if (!value) {
          return t`Oznaka prostora je obvezna`;
        }

        if (!/^[0-9a-zA-Z]*$/.test(value)) {
          //   return "Business premise ID can only contain letters and numbers";
          return t`Oznaka prostora lahko vsebuje samo črke in številke`;
        }
      },
    },
  });

  useEffect(() => {
    if (!businessPremise_id || !isManageModalOpen) return;

    const existingBusinessPremise = businessPremises?.find(
      (premise) => premise.id === businessPremise_id,
    );

    if (!existingBusinessPremise) return;

    if (existingBusinessPremise.type === "realestate") {
      form.setValues({
        ...existingBusinessPremise,
        startNumberingWith:
          existingBusinessPremise.numbers[dayjs().format("YYYY")],
        type: "realestate",
        premiseType: null,
      });
    } else if (existingBusinessPremise.type === "movable") {
      form.setValues({
        ...existingBusinessPremise,
        type: "movable",
        startNumberingWith:
          existingBusinessPremise.numbers[dayjs().format("YYYY")],
        premiseType:
          (existingBusinessPremise.premiseType as Form["premiseType"]) ||
          undefined,
      });
    }
  }, [businessPremises, businessPremise_id]);

  const locationSelectItems: SingleSelect["items"] =
    locations?.locations.map((location) => ({
      key: location.locationId.toString(),
      label: location.label,
      sectionName: t`Obstoječe lokacije`,
    })) || [];
  locationSelectItems.unshift({
    key: "NEW",
    label: t`Ustvari novo lokacijo`,
    startContent: <Plus size={16} />,
    sectionName: t`Nova lokacija`,
  });

  const handleSubmitForm = async (values: Form) => {
    const response = await (async () => {
      if (businessPremise_id == null) {
        const response = await (async () => {
          if (values.type === "realestate") {
            const res = await createBusinessPremise({
              ...values,
              locationId: values.locationId!,
              type: "realestate",
              premiseType: null,
            });
            return res;
          } else {
            const res = await createBusinessPremise({
              ...values,
              locationId: values.locationId!,
              type: "movable",
              premiseType: values.premiseType,
            });
            return res;
          }
        })();
        return response;
      } else {
        const response = await updateBusinessPremise({
          businessPremiseId: businessPremise_id,
          body: {
            ...values,
            locationId: values.locationId!,
          },
        });
        return response;
      }
    })();

    if (response != null) {
      onOpenManageModalChange(response.id);
      refetchLocations();

      form.reset();

      notifications.show({
        message:
          businessPremise_id == null
            ? t`Poslovni prostor je bil uspešno ustvarjen`
            : t`Poslovni prostor je bil uspešno posodobljen`,
      });
    }
  };

  const fursStrategy = organization?._defaults.find(
    (d) => d.name === "furs_strategy",
  )?.value as string | undefined;

  return (
    <>
      <Modal
        isOpen={isManageModalOpen}
        onOpenChange={(isOpen) => {
          onOpenManageModalChange();

          if (!isOpen) {
            form.reset();
          }
        }}
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={form.onSubmit(handleSubmitForm)}>
                <ModalHeader>
                  {businessPremise_id == null ? (
                    <Trans>Dodaj poslovni prostor</Trans>
                  ) : (
                    <Trans>Uredi poslovni prostor</Trans>
                  )}
                </ModalHeader>
                <ModalBody>
                  <LimeInput
                    label={t`Oznaka prostora`}
                    {...form.getInputProps("businessPremiseId")}
                    isRequired
                    isDisabled={businessPremise_id != null}
                  />

                  <LimeSelect
                    label={t`Ponudnik`}
                    value={form.getValues().provider}
                    onChange={(val: Form["provider"]) => {
                      form.setFieldValue("provider", val);
                    }}
                    isDisabled
                    errorMessage={form.errors.provider}
                    isInvalid={!!form.errors.provider}
                    items={[
                      {
                        key: "furs",
                        label: "FURS",
                      },
                      {
                        key: "fina",
                        label: "FINA",
                      },
                    ]}
                  ></LimeSelect>

                  <LimeSelect
                    label={t`Lokacija`}
                    isRequired
                    isLoading={isFetchingLocations}
                    disabledKeys={
                      locations?.locations
                        .filter((location) =>
                          location.BusinessPremise.some(
                            (businessPremise) =>
                              businessPremise.organizationId ===
                              organization?.id,
                          ),
                        )
                        .map((location) => location.locationId.toString()) ?? []
                    }
                    items={locationSelectItems}
                    value={form.getValues().locationId}
                    onChange={(val: string) => {
                      if (val === "NEW") {
                        onOpenCreateLocationModal();
                        return;
                      }

                      form.setFieldValue("locationId", Number(val));
                    }}
                  ></LimeSelect>

                  {fursStrategy == "C" ? (
                    <LimeInput
                      label={t`Začni številčenje z`}
                      {...form.getInputProps("startNumberingWith")}
                      type="number"
                    />
                  ) : undefined}

                  <div className="my-2 flex flex-col gap-2">
                    <p className="text-sm font-semibold">
                      <Trans>Izberite vrsto poslovnega prostora</Trans>
                    </p>
                    <Tabs
                      fullWidth
                      aria-label={t`Vrsta poslovnega prostora`}
                      selectedKey={form.getValues().type}
                      onSelectionChange={(value) => {
                        form.setFieldValue("type", value as Form["type"]);
                      }}
                    >
                      <Tab key={"realestate"} title={t`Nepremični`}></Tab>
                      <Tab key={"movable"} title={t`Premični`}></Tab>
                    </Tabs>
                  </div>

                  {form.getValues().type === "movable" && (
                    <>
                      <LimeSelect
                        label={t`Vrsta premičnega prostora`}
                        value={form.getValues().premiseType}
                        onChange={(val: string) => {
                          form.setFieldValue(
                            "premiseType",
                            val as Form["premiseType"],
                          );
                        }}
                        isInvalid={!!form.errors.premiseType}
                        errorMessage={form.errors.premiseType}
                        items={["A", "B", "C"].map((type) => ({
                          key: type,
                          label: type,
                        }))}
                      ></LimeSelect>

                      <div className="flex flex-col text-sm">
                        <p className="font-semibold">
                          <Trans>Tip A</Trans>
                        </p>

                        <p>
                          <Trans>
                            Premičen objekt (npr. prevozno sredstvo, premična,
                            stojnica)
                          </Trans>
                        </p>

                        <p className="mt-3 font-semibold">
                          <Trans>Tip B</Trans>
                        </p>

                        <p>
                          <Trans>
                            Objekt na stalni lokaciji (npr. stojnica na tržnici,
                            kiosk)
                          </Trans>
                        </p>

                        <p className="mt-3 font-semibold">
                          <Trans>Tip C</Trans>
                        </p>

                        <p>
                          <Trans>
                            Posamezna elektronska naprava za izdajo računov ali
                            vezana knjiga računov v primerih, ko zavezanec ne
                            uporablja drugega poslovnega prostora
                          </Trans>
                        </p>
                      </div>
                    </>
                  )}

                  {form.getValues().type === "realestate" && (
                    <>
                      <LimeInput
                        label={t`Ulica`}
                        {...form.getInputProps("street")}
                        placeholder={t`Tržaška cesta`}
                        isRequired
                      />

                      <LimeInput
                        label={t`Hišna št.`}
                        {...form.getInputProps("houseNumber")}
                        type="number"
                        placeholder="13"
                        isRequired
                      />

                      <LimeInput
                        label={t`Dodatna hišna št.`}
                        {...form.getInputProps("houseNumberAdditional")}
                        placeholder="B"
                      />

                      <LimeInput
                        label={t`Mesto`}
                        {...form.getInputProps("city")}
                        placeholder={t`Ljubljana`}
                        isRequired
                      />

                      <LimeInput
                        label={t`Pošta`}
                        {...form.getInputProps("postalCode")}
                        maxLength={4}
                        placeholder="1000"
                        isRequired
                      />

                      <LimeInput
                        label={t`Občina`}
                        {...form.getInputProps("community")}
                        placeholder={t`Ljubljana`}
                        isRequired
                      />

                      <LimeInput
                        label={t`Katastrska št.`}
                        {...form.getInputProps("cadastralNumber")}
                        type="number"
                        isRequired
                      />

                      <LimeInput
                        label={t`Št. stavbe`}
                        {...form.getInputProps("buildingNumber")}
                        type="number"
                        isRequired
                      />

                      <LimeInput
                        label={t`Št. dela stavbe`}
                        {...form.getInputProps("buildingSectionNumber")}
                        type="number"
                        isRequired
                      />
                    </>
                  )}

                  {createErrorMessage && (
                    <LimeAlert color="danger" message={createErrorMessage} />
                  )}

                  {updateErrorMessage && (
                    <LimeAlert color="danger" message={updateErrorMessage} />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    variant="flat"
                    onPress={onClose}
                    isDisabled={
                      isCreatingBusinessPremise || isUpdatingBusinessPremise
                    }
                  >
                    <Trans>Prekliči</Trans>
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    isLoading={
                      isCreatingBusinessPremise || isUpdatingBusinessPremise
                    }
                  >
                    {businessPremise_id == null ? (
                      <Trans>Dodaj</Trans>
                    ) : (
                      <Trans>Uredi</Trans>
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>

      <CreateLocationDialog
        isOpen={isCreateLocationModalOpen}
        handleClose={async (locationId) => {
          if (locationId != null) {
            await refetchLocations();
            form.setFieldValue("locationId", locationId);
          }

          onCloseCreateLocationModal();
        }}
      />
    </>
  );
};
