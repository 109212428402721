import { TimeInput, TimeInputProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";

type TimeInput = MantineFormValues & TimeInputProps;

export const LimeTimeInput = React.forwardRef(
  ({ error, ...props }: TimeInput, ref: React.Ref<HTMLInputElement>) => (
    <TimeInput ref={ref} errorMessage={error} isInvalid={!!error} {...props} />
  ),
);
LimeTimeInput.displayName = "LimeTimeInput";
