import axios, { type AxiosRequestConfig } from "axios";

export const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_BE_HOST,
});

type TEndpoint<
  TPath = string,
  TQuery = unknown,
  TBody = unknown,
  TResponse = unknown,
> = {
  path: TPath;
  query?: TQuery;
  body?: TBody;
  response: TResponse;
};

/**
 * GET
 */
export async function get<T extends TEndpoint>(
  path: T["path"],
  query?: T["query"],
  configOverride?: AxiosRequestConfig,
) {
  const { data } = await axiosClient.get<T["response"]>(path, {
    params: query,
    ...getConfig(configOverride),
  });
  return data;
}

/**
 * POST
 */
export async function post<T extends TEndpoint>(
  path: T["path"],
  body?: T["body"],
  query?: T["query"],
  configOverride?: AxiosRequestConfig,
) {
  const { data } = await axiosClient.post<T["response"]>(path, body, {
    params: query,
    ...getConfig(configOverride),
  });
  return data;
}

/**
 * PUT
 */
export async function put<T extends TEndpoint>(
  path: T["path"],
  body?: T["body"],
  query?: T["query"],
  configOverride?: AxiosRequestConfig,
) {
  const { data } = await axiosClient.put<T["response"]>(path, body, {
    params: query,
    ...getConfig(configOverride),
  });
  return data;
}

/**
 * DELETE
 */
export async function del<T extends TEndpoint>(
  path: T["path"],
  query?: T["query"],
  configOverride?: AxiosRequestConfig,
) {
  const { data } = await axiosClient.delete<T["response"]>(path, {
    params: query,
    ...getConfig(configOverride),
  });
  return data;
}

/**
 * HELPERS
 */
export function getConfig(configOverride: AxiosRequestConfig = {}) {
  const token = (() => {
    try {
      return localStorage.getItem("usr_token") || "";
    } catch (error) {
      console.warn("localStorage access failed:", error);
      return "";
    }
  })();

  return {
    headers: {
      "x-custom-locale": axios.defaults.headers.common["x-custom-locale"],
      "x-access-token": token.replaceAll('"', ""),
    },
    withCredentials: true,
    ...configOverride,
  } satisfies AxiosRequestConfig;
}
