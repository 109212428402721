import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
/**
 * This component checks how far the user
 * has gone in setting up their tax register
 */

import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import { Button, Checkbox, CircularProgress, Link } from "@heroui/react";
import { AlertCircleIcon } from "lucide-react";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const TaxRegisterSetup = () => {
  const { data: posAuthData } = api.cookie.useGetPosToken();

  const { data, isFetching } = api.taxRegister.useGetOrganizationSetupProgress(
    posAuthData?.userId == null,
  );

  return (
    <>
      <TaxRegisterAuthorizationDialog requiresSetupCompleted={false} />

      <LimePageHeader title={t`Vzpostavite davčno blagajno`} />

      {data?.isTestEnvironment && (
        <LimeAlert
          color="danger"
          className="mx-4 mt-4 md:mx-8"
          message={t`Davčna blagajna je v preizkusnem načinu`}
        />
      )}

      <div className="mt-4 px-4 md:px-8">
        <Content
          completedSteps={data?.completedSteps || []}
          isSkeleton={isFetching}
        />
      </div>
    </>
  );
};

const Content = ({
  completedSteps,
  isSkeleton,
}: {
  completedSteps: string[];
  isSkeleton: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4 text-sm">
      <p>
        <Trans>
          V nadaljevanju se nahaja vodič po korakih, ki vam bo pomagal pri
          vklopu davčnega potrjevanja računov.
        </Trans>
      </p>

      <div className="p-2">
        <div className="mb-2 flex items-center gap-2 text-gray-400">
          <AlertCircleIcon size={20} />
          <p className="text-[12px]">
            <Trans>POMEMBNO</Trans>
          </p>
        </div>
        <p>
          <Trans>
            Uporabnikom svetujemo, da se pred uporabo modula preberejo navodila
            ali pa posvetujejo z računovodstvom o določilih davčnega
            potrjevanja, ki jih določa FURS, ter si s tem zagotovijo pravilno
            izdajanje davčno potrjenih računov. Program izpolnjuje in zagotavlja
            tehnično pravilnost delovanja glede na predpise, ne pa kontrolo nad
            vnešeno vsebino, zato ne moramo biti odgovorni za neustrezno rabo
            programa s strani uporabnika.
          </Trans>
        </p>
      </div>

      <Steps completedSteps={completedSteps} isSkeleton={isSkeleton} />
    </div>
  );
};

const Steps = ({
  completedSteps,
  isSkeleton,
}: {
  completedSteps: string[];
  isSkeleton: boolean;
}) => {
  const taxRegisterSetupSteps = [
    {
      id: "info",
      label: t`Informacije o podjetju`,
    },
    {
      id: "cert",
      label: t`Certifikat`,
      isDisabled: !completedSteps.includes("info"),
    },
    {
      id: "business-premise",
      label: t`Poslovni prostori`,
      isDisabled:
        !completedSteps.includes("info") || !completedSteps.includes("cert"),
    },
    {
      id: "electronic-devices",
      label: t`Elektronske naprave`,
      isDisabled:
        !completedSteps.includes("info") ||
        !completedSteps.includes("cert") ||
        !completedSteps.includes("business-premise"),
    },
    {
      id: "act",
      label: t`Akt`,
      isDisabled:
        !completedSteps.includes("info") ||
        !completedSteps.includes("cert") ||
        !completedSteps.includes("business-premise") ||
        !completedSteps.includes("electronic-devices"),
    },
    {
      id: "printers",
      label: t`Tiskalniki`,
      isDisabled: !completedSteps.includes("info"),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {taxRegisterSetupSteps.map((step, index) => (
        <Step
          key={step.id}
          index={index}
          step={step.id}
          label={step.label}
          isCompleted={completedSteps.includes(step.id)}
          isSkeleton={isSkeleton}
          isDisabled={step.isDisabled}
        />
      ))}
    </div>
  );
};

const Step = ({
  index,
  label,
  step,
  isCompleted,
  isSkeleton,
  isDisabled,
}: {
  index: number;
  label: string;
  step: string;
  isCompleted: boolean;
  isSkeleton: boolean;
  isDisabled?: boolean;
}) => {
  return (
    // </Skeleton>
    (<Button
      className="h-12 w-full justify-between"
      aria-label={label}
      variant="flat"
      endContent={
        isSkeleton ? (
          <CircularProgress
            classNames={{
              svg: "h-7 w-7",
              base: "p-0",
            }}
          />
        ) : (
          <Checkbox
            isSelected={isCompleted}
            isReadOnly
            radius="full"
            size="lg"
            classNames={{
              base: "p-0",
            }}
          />
        )
      }
      as={Link}
      href={`/dashboard/tax-register/settings/setup/${step}`}
      isDisabled={isSkeleton || isDisabled}
    >
      {index + 1}. {label}
    </Button>)
  );
};
