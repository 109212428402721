import dayjs from "dayjs";
import { useCalendarStore } from "../../store/useCalendarStore";
import { useRef } from "react";

const useDrag = () => {
  const dragTimeoutRef = useRef(null);
  const dragTimedOutRef = useRef(false);

  const slotHeight = useCalendarStore((state) => state.calendarData.slotHeight);
  const startTime = useCalendarStore((state) => state.calendarData.startTime);
  const timeStep = useCalendarStore((state) => state.calendarData.timeStep);

  const setRescheduleData = useCalendarStore(
    (state) => state.setRescheduleData,
  );
  const { draggingId, setDraggingId, lastClickYOffset, setLastClickYOffset } =
    useCalendarStore((state) => state);
  const resources = useCalendarStore((state) => state.resources);

  function handleDragStart(event) {
    const { appointmentId } = event.active.data.current.appointment;
    setDraggingId(appointmentId);
    dragTimedOutRef.current = false;

    // Dispatch a synthetic mouseup event to force drag timeout after 10 seconds.
    dragTimeoutRef.current = setTimeout(() => {
      dragTimedOutRef.current = true;
      setDraggingId(null);
      unHoverAll();
      document.dispatchEvent(new MouseEvent("mouseup", { bubbles: true }));
    }, 10000);
  }

  function handleDragEnd(event) {
    if (dragTimeoutRef.current) {
      clearTimeout(dragTimeoutRef.current);
      dragTimeoutRef.current = null;
    }

    setDraggingId(null);
    unHoverAll();

    // If the drag timed out, cancel any reschedule logic.
    if (dragTimedOutRef.current) {
      setLastClickYOffset(0);
      return;
    }

    const { active, over } = event;
    if (over && active?.id !== over?.id) {
      //reschedule data
      const appointment = active.data.current.appointment;
      const date = over.data.current.date;
      let newOffset =
        active.data.current.yOffset + event.delta.y + lastClickYOffset;

      if (newOffset < 0) {
        newOffset = 0;
      } else {
        // newOffset = Math.round(newOffset / slotHeight) * slotHeight;
      }

      appointment.yOffset = newOffset;
      appointment.date = date;
      appointment.formattedData.date = dayjs(date).format("DD. MM. YYYY");
      appointment.startTime = calculateTimeForDragYOffset(newOffset);
      appointment.formattedData.startTime =
        calculateTimeForDragYOffset(newOffset);

      const { currentResources, currentUsers } = active.data.current;
      const { resources, userIds: users } = appointment;

      if (currentResources) {
        appointment.oldResources = [...appointment.resources];
        const allCollisions = event.collisions;
        allCollisions.sort((a, b) => b.data.value - a.data.value);

        // take as many resource collisions as there were resources on initial appointment
        const collisionsSliced = allCollisions.slice(
          0,
          currentResources.length,
        );

        const newDate =
          collisionsSliced.at(0).data.droppableContainer.data.current.date;

        const allOnSameDate = collisionsSliced.every(
          (c) => c.data.droppableContainer.data.current.date === newDate,
        );

        if (!allOnSameDate) {
          return false;
        }

        const newResources = resources
          .filter((r) => !currentResources.includes(r))
          .concat(
            collisionsSliced.map(
              (c) => c.data.droppableContainer.data.current.resourceId,
            ),
          );

        appointment.resources = [...new Set(newResources)];
      }
      if (currentUsers) {
        const allCollisions = event.collisions;
        allCollisions.sort((a, b) => b.data.value - a.data.value);

        // take as many resource collisions as there were resources on initial appointment
        const collisionsSliced = allCollisions.slice(0, currentUsers.length);

        const newDate =
          collisionsSliced.at(0).data.droppableContainer.data.current.date;

        const allOnSameDate = collisionsSliced.every(
          (c) => c.data.droppableContainer.data.current.date === newDate,
        );

        if (!allOnSameDate) {
          return false;
        }

        const newUsers = users
          .filter((u) => !currentUsers.includes(u))
          .concat(
            collisionsSliced.map(
              (c) => c.data.droppableContainer.data.current.userId,
            ),
          );

        appointment.users = [...new Set(newUsers)];
      }

      setRescheduleData({
        appointment,
        sendNotifications: false,
      });
    }
    setLastClickYOffset(0);
  }

  function calculateTimeForYOffset(yOffset) {
    if (yOffset < 0) {
      yOffset = 0;
    } else {
      const index = Math.trunc(yOffset / slotHeight);
      yOffset = index * slotHeight;
    }
    const rowIndex = yOffset / slotHeight;
    const time = dayjs(startTime, "HH:mm").add(rowIndex * timeStep, "minutes");
    return time.format("HH:mm");
  }

  function calculateTimeForDragYOffset(yOffset) {
    if (yOffset < 0) {
      yOffset = 0;
    }
    const rowIndex = yOffset / slotHeight;
    let time = dayjs(startTime, "HH:mm").add(rowIndex * timeStep, "minute");
    const timeOffset = Math.round(time.minute() / 5) * 5 - time.minute();
    time = time.add(timeOffset, "minute");
    return time.format("HH:mm");
  }

  function calculateYOffsetForTime(time) {
    const [hour, minute] = time.split(":").map(Number);
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const minutesFromStart = (hour - startHour) * 60 + (minute - startMinute);
    const rowIndex = minutesFromStart / timeStep;
    return rowIndex * slotHeight;
  }

  function handleDragMove(event) {
    const { collisions } = event;
    unHoverAll();
    const { active } = event;
    const { currentResources, currentUsers } = active.data.current;
    if (!collisions || collisions.length < 1) return;
    const allCollisions = collisions.sort(
      (a, b) => b.data.value - a.data.value,
    );

    // take as many resource collisions as there were resources on initial appointment
    const collisionsSliced = allCollisions.slice(
      0,
      currentResources ? currentResources.length : currentUsers.length,
    );
    const newDate =
      collisionsSliced.at(0).data.droppableContainer.data.current.date;
    active.data.current.appointment.hoverDate = newDate;
    const allOnSameDate = collisionsSliced.every(
      (c) => c.data.droppableContainer.data.current.date === newDate,
    );
    if (!allOnSameDate) {
      return false;
    }
    for (const c of collisionsSliced) {
      c.data.droppableContainer.node.current.classList.add("hovering");
    }
  }

  function unHoverAll() {
    const allCurrentHovered = document.getElementsByClassName("hovering");
    for (const item of [...allCurrentHovered]) {
      item.classList.remove("hovering");
    }
  }

  return {
    handleDragEnd,
    calculateTimeForYOffset,
    calculateTimeForDragYOffset,
    calculateYOffsetForTime,
    handleDragMove,
    handleDragStart,
    draggingId,
  };
};

export default useDrag;
