import { Input, InputOtp, InputOtpProps } from "@heroui/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";

type Input = MantineFormValues & InputOtpProps;

export const LimeInputOtp = React.forwardRef(
  ({ error, ...props }: Input, ref: React.Ref<HTMLInputElement>) => (
    <InputOtp
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      onChange={undefined}
      onValueChange={props.onChange}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
      //   inputMode={props.type === "number" ? "numeric" : props.inputMode}
    />
  ),
);
LimeInputOtp.displayName = "LimeInputOtp";
