import { Modal, ModalProps } from "@heroui/react";
import React from "react";

export const LimeModal = ({
  children,
  ...props
}: { children: React.ReactNode } & ModalProps) => {
  return (
    <Modal
      scrollBehavior={props.scrollBehavior ? props.scrollBehavior : "outside"}
      {...props}
    >
      {children}
    </Modal>
  );
};
