import { t } from "@lingui/core/macro";
import { LimeSelect } from "@/Components/LimeSelect";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { Collapse, Divider, Flex } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "../ManageService";
import { client } from "@/server-types";

export const AdditionalTab = ({
  form,
  taxAccountType,
}: {
  form: UseFormReturnType<FormValues>;
  taxAccountType: NonNullable<client["taxAccountType"]>;
}) => {
  console.log(form.getValues());
  return (
    <Flex direction={"column"}>
      {taxAccountType === "medicine" ? (
        <LimeSwitch
          {...form.getInputProps("isMedicineService", {
            type: "checkbox",
          })}
          label={t`Medicinska storitev`}
          // toolTip="PLACEHOLDER"
          size="md"
          px={"md"}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        />
      ) : undefined}

      <LimeSwitch
        {...form.getInputProps("shouldCalculateTax", {
          type: "checkbox",
        })}
        label={t`Obračun davka`}
        // toolTip="PLACEHOLDER"
        size="md"
        px={"md"}
        autoFocus={taxAccountType !== "medicine"}
      />

      <Collapse in={form.getValues().shouldCalculateTax}>
        <LimeSelect
          {...form.getInputProps("isPriceWithTax")}
          value={form.getValues().isPriceWithTax.toString()}
          onChange={(value) => {
            form.setFieldValue("isPriceWithTax", value === "true");
          }}
          label={t`Cene so vnešene:`}
          data={[
            { label: t`Z davkom`, value: "true" },
            { label: t`Brez davka`, value: "false" },
          ]}
          allowDeselect={false}
          px={"md"}
          mt={"md"}
        />

        <Divider my={"md"} />

        <LimeSelect
          {...form.getInputProps("taxPercentage")}
          value={form.getValues().taxPercentage.toString()}
          label={t`Davčna stopnja`}
          data={[
            { label: t`Brez DDV (0%)`, value: "0" },
            { label: t`Znižana stopnja (9.5%)`, value: "9.5" },
            { label: t`Standardna stopnja (22%)`, value: "22" },
          ]}
          allowDeselect={false}
          px={"md"}
        />
      </Collapse>

      <Divider my={"md"} />
    </Flex>
  );
};
