type FormatCurrencyShared = {
  amount: number;
  fractionDigits?: number;
  shouldSanitize?: boolean;
  options?: Intl.NumberFormatOptions;
};
type FormatCurrencyWithLocale = FormatCurrencyShared & { locale: string };
type FormatCurrencyWithCurrency = FormatCurrencyShared & { currency: string };
export type FormatCurrency = FormatCurrencyWithLocale &
  FormatCurrencyWithCurrency;

const currencyToLocale: Record<string, string> = {
  EUR: "sl",
  USD: "en",
  AED: "en",
  BAM: "sr",
  RDS: "sr",
};
const localeToCurrency: Record<string, string> = {
  sl: "EUR",
  en: "USD",
  sr: "RDS",
};
export function formatCurrency({
  amount,
  fractionDigits,
  options,
  shouldSanitize,
  ...rest
}: FormatCurrency): string {
  const currency = rest.currency || localeToCurrency[rest.locale] || "EUR";

  const locale = rest.locale || currencyToLocale[rest.currency.toUpperCase()];

  const formattedAmount = new Intl.NumberFormat(locale ?? "sl", {
    style: "currency",
    currency: currency.toUpperCase(),
    maximumFractionDigits: fractionDigits ?? 2,
    currencyDisplay: "symbol",
    ...options,
  }).format(amount);

  if (shouldSanitize) {
    /**
     * Replaces non-breaking space with regular space
     * This broke ESC/POS printing since printers can't interpret non-breaking space
     */
    return formattedAmount.replace(/\u00A0/g, " ");
  }

  return formattedAmount;
}

export function returnCurrencySymbol({ currency }: { currency: string }) {
  const string = new Intl.NumberFormat("sl", {
    currency,
    currencyDisplay: "symbol",
    style: "currency",
  });

  const parts = string.formatToParts(0);

  const symbolPart = parts.find((part) => part.type === "currency");
  return symbolPart?.value || "";
}

export function hasProperty<T extends string>(
  obj: unknown,
  prop: T,
  type?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): obj is { [key in T]: any } {
  return (
    typeof obj === "object" &&
    obj !== null &&
    prop in obj &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (type ? typeof (obj as { [key: string]: any })[prop] === type : true)
  );
}
