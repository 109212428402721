import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { Button, Flex, Table, Text, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { LuTrash } from "react-icons/lu";
import { RiExternalLinkLine } from "react-icons/ri";
import { FormDetails } from "./FormDetails";
import { Snippet } from "@heroui/react";

export const FormsTab = ({ clientId }: { clientId: number }) => {
  const { data, refetch } = api.admin.useClientForms(clientId);

  const { mutateAsync: createForm } = api.admin.useCreateClientForm();

  const [selectedFormId, setSelectedFormId] = useState<number | null>(null);

  const [newFormHash, setNewFormHash] = useState("");

  useEffect(() => {
    if (selectedFormId) return;

    refetch();
  }, [selectedFormId, refetch]);

  const handleCreateForm = async () => {
    try {
      await createForm({ clientId, formHash: newFormHash });

      refetch();
      notifications.show({
        message: "Forma uspešno ustvarjena",
        color: "green",
      });
      setNewFormHash("");
    } catch (e) {
      notifications.show({
        message: "Napaka pri ustvarjanju forme",
        color: "red",
      });
    }
  };

  return (
    <>
      {selectedFormId ? (
        <FormDetails
          clientId={clientId}
          formId={selectedFormId}
          close={() => setSelectedFormId(null)}
        ></FormDetails>
      ) : (
        <Flex direction={"column"} gap={20} mt={20}>
          <Flex gap={50}>
            <Text>Ustvari novo formo</Text>
            <TextInput
              value={newFormHash}
              onChange={(e) => setNewFormHash(e.target.value)}
              placeholder="hash za link"
            ></TextInput>
            <Button
              variant="outline"
              disabled={newFormHash === ""}
              onClick={handleCreateForm}
            >
              Ustvari novo formo
            </Button>
          </Flex>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>ID</Table.Th>
                <Table.Th>Ime form</Table.Th>
                <Table.Th>Hash</Table.Th>
                <Table.Th>Link</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.forms.map((form) => (
                <Table.Tr
                  key={form.id}
                  style={{
                    cursor: "pointer",
                    background: form.isDeleted ? "#ebebeb" : "none",
                  }}
                  onClick={() => setSelectedFormId(form.id)}
                >
                  <Table.Td>
                    <Snippet symbol="" variant="bordered" className="border-0">
                      {form.id}
                    </Snippet>
                  </Table.Td>
                  <Table.Td>{form.name}</Table.Td>
                  <Table.Td>{form.hash}</Table.Td>
                  <Table.Td onClick={(e) => e.stopPropagation()}>
                    {form.isDeleted ? (
                      <Flex gap={"xs"} align={"center"} c={Color.Error}>
                        <LuTrash></LuTrash>
                        <p>IZBRISANA !!!!</p>
                      </Flex>
                    ) : (
                      <a
                        href={`${import.meta.env.VITE_FORM_URL}/${
                          form.defaultLanguage ?? "sl"
                        }/${form.hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <RiExternalLinkLine></RiExternalLinkLine>
                      </a>
                    )}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};
