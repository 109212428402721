import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Skeleton,
  useDisclosure,
} from "@heroui/react";
import { CirclePlus } from "lucide-react";
import { LuExternalLink } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { BusinessPremiseCard } from "./BusinessPremiseCard";
import { ManageBusinessPremiseModal } from "./ManageBusinessPremiseModal";
import { useState } from "react";
import { TaxRegisterAuthorizationDialog } from "../../AuthorizationDialog";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const TaxRegisterSetupBusinessPremise = () => {
  const navigate = useNavigate();

  const [selectedPremiseId, setSelectedPremiseId] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: isManageModalOpen,
    onOpen: onOpenManageModal,
    onOpenChange: onOpenManageModalChange,
  } = useDisclosure();

  const {
    data: premises,
    isPending,
    isSuccess,
    refetch: refetchBusinessPremises,
    processedErrorMessage,
  } = api.taxRegister.useGetOrganizationBusinessPremise({});

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchBusinessPremises();
        }}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Vzpostavitev podjetja`}
        subPage={{
          title: t`Poslovni prostori`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex flex-col px-4 md:px-8">
        <p className="text-sm">
          <Trans>
            Poslovni prostor je vsak{" "}
            <span className="font-semibold">nepremičen</span> ali{" "}
            <span className="font-semibold">premičen prostor</span>, v katerem
            zavezanec stalno, občasno ali začasno izdaja račune za dobave blaga
            ali storitev pri gotovinskem poslovanju. Poslovnemu prostoru je
            treba dodeliti interno oznako ter ga popisati najmanj do nivoja
            naslova.
          </Trans>
        </p>

        <Card className="my-4 rounded-md bg-gray-100" shadow="none">
          <CardBody>
            <p className="text-sm">
              <Trans>
                Katastrske podatke vaših poslovnih prostorov lahko najdete na
                spodnji povezavi.
              </Trans>
            </p>
          </CardBody>
          <CardFooter>
            <Button
              className="w-full"
              variant="bordered"
              endContent={<LuExternalLink />}
              as="a"
              href="https://eprostor.gov.si/javni/vpogled"
              target="_blank"
            >
              <Trans>GOV javni vpogled</Trans>
            </Button>
          </CardFooter>
        </Card>

        <LimeAlert color="danger" message={processedErrorMessage} />

        <p className="mb-4 mt-4 font-semibold">
          <Trans>Seznam prostorov</Trans>
        </p>

        {isSuccess && premises.length === 0 && (
          <p className="pb-4 text-sm">
            <Trans>Nimate prostorov</Trans>
          </p>
        )}

        {isPending &&
          Array.from({ length: 2 }).map((_) => (
            <Skeleton className="mb-4 h-40 w-full rounded-xl" />
          ))}

        {isSuccess &&
          premises.length > 0 &&
          premises.map((premise) => (
            <BusinessPremiseCard
              key={premise.id}
              businessPremise={premise}
              handleEditBusinessPremise={() => {
                setSelectedPremiseId(premise.id);
                onOpenManageModal();
              }}
            />
          ))}

        <Button
          className="w-fit"
          startContent={<CirclePlus />}
          variant="light"
          onPress={() => {
            setSelectedPremiseId(undefined);
            onOpenManageModal();
          }}
          isDisabled={isPending}
        >
          <Trans>Dodaj poslovni prostor</Trans>
        </Button>
      </div>

      <ManageBusinessPremiseModal
        isManageModalOpen={isManageModalOpen}
        onOpenManageModalChange={(businessPremise_id?: string) => {
          setSelectedPremiseId(undefined);
          onOpenManageModalChange();

          if (businessPremise_id != null) {
            refetchBusinessPremises();
          }
        }}
        businessPremise_id={selectedPremiseId}
      />
    </>
  );
};

type SharedForm = {
  businessPremiseId: string;
  provider: "furs" | "fina";
  locationId: number | null;
  startNumberingWith: number;
};

type MovableForm = {
  type: "movable";
  premiseType: "A" | "B" | "C";
};

type RealEstateForm = {
  type: "realestate";
  premiseType: null;
  cadastralNumber: number;
  buildingNumber: number;
  buildingSectionNumber: number;
  street: string;
  houseNumber: string;
  houseNumberAdditional: string;
  community: string;
  city: string;
  postalCode: string;
};

export type Form = SharedForm & (MovableForm | RealEstateForm);
