import { PutCustomerAppointmentsAttendance } from "@/server-types";
import { useCustomMutation } from "../custom-mutation";
import { put } from "../request-helpers";

/**
 * PUT /customer-appointments/attendance
 */
export const useUpdateCustomerAppointmentsAttendance = () => {
  return useCustomMutation({
    mutationFn: ({
      body,
    }: {
      body: PutCustomerAppointmentsAttendance["body"];
    }) =>
      put<PutCustomerAppointmentsAttendance>(
        `/customer-appointment/attendance`,
        body,
      ),
  });
};
