import { Accordion, AccordionItem } from "@heroui/react";

export const LimeCollapse = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Accordion
      selectedKeys={isOpen ? ["1"] : undefined}
      itemClasses={{
        heading: "hidden",
      }}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            overflowY: "unset",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease-out",
                duration: 0.2,
              },
            },
          },
          exit: {
            y: 0,
            opacity: 0,
            height: 0,
            overflowY: "hidden",
            transition: {
              height: {
                easings: "spring",
                stiffness: 500,
                damping: 30,
                duration: 0.2,
              },
              opacity: {
                easings: "ease-out",
                duration: 0.2,
              },
            },
          },
        },
      }}
    >
      <AccordionItem key={"1"}>{children}</AccordionItem>
    </Accordion>
  );
};
