import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../lib/api-client/index";
import { useForm } from "@mantine/form";
import { Button, Divider } from "@heroui/react";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { useEffect } from "react";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { notifications } from "@mantine/notifications";
import { countries } from "../../../../../../shared/constants/countries";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { isValidIBAN, isValidBIC } from "ibantools";

const bankDetailsValidator = (values: Form, field: string) => {
  const isAnyDetailEntered =
    !!values.organization.IBAN ||
    !!values.organization.SWIFT ||
    !!values.organization.bank;

  if (!isAnyDetailEntered) {
    return null;
  }

  if (
    !values.organization.IBAN ||
    !values.organization.SWIFT ||
    !values.organization.bank
  ) {
    return t`Please enter all bank details`;
  }

  const isIBANValid = isValidIBAN(
    values.organization.IBAN.trim().replaceAll(" ", ""),
  );
  if (field === "IBAN" && !isIBANValid) {
    return t`Please enter a valid IBAN`;
  }

  const isBICValid = isValidBIC(
    values.organization.SWIFT.trim().replaceAll(" ", ""),
  );
  if (field === "SWIFT" && !isBICValid) {
    return t`Please enter a valid BIC`;
  }

  return null;
};

export const TaxRegisterSetupBasicInfo = () => {
  const navigate = useNavigate();

  const {
    mutateAsync: createOrganization,
    isPending: isCreatingOrganization,
    processedErrorMessage: createOrganizationErrorMessage,
  } = api.taxRegister.usePostCreateOrganization();

  const {
    mutateAsync: updateOrganization,
    isPending: isUpdatingOrganization,
    processedErrorMessage: updateOrganizationErrorMessage,
  } = api.taxRegister.useUpdateOrganization();

  const {
    data: organization,
    isPending: isFetchingOrganization,
    refetch: refetchOrganization,
  } = api.taxRegister.useGetOrganization({});

  const isExistingOrganization = !isFetchingOrganization && !!organization;

  const form = useForm<Form>({
    initialValues: {
      organization: {
        name: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        organizationTaxNumber: "",
        isTaxSubject: false,

        IBAN: undefined,
        SWIFT: undefined,
        bank: undefined,
      },
      fursStrategy: "C",
    },

    validate: {
      organization: {
        name: (value) => (value === "" ? t`Ime je obvezno` : null),
        address: (value) => (value === "" ? t`Naslov je obvezen` : null),
        zip: (value) => (value === "" ? t`Pošta je obvezna` : null),
        city: (value) => (value === "" ? t`Mesto je obvezno` : null),
        country: (value) => (value === "" ? t`Država je obvezna` : null),

        IBAN: (_, values) => bankDetailsValidator(values, "IBAN"),
        SWIFT: (_, values) => bankDetailsValidator(values, "SWIFT"),
        bank: (_, values) => bankDetailsValidator(values, "BANK"),

        organizationTaxNumber: (value) => {
          if (!value || value === "") {
            return t`Davčna številka je obvezna`;
          }

          if (Number(value) < 10000000 || Number(value) > 99999999) {
            return t`Davčna številka mora biti veljavna`;
          }
        },
      },
    },
  });

  console.log("values", form.getValues());

  useEffect(() => {
    if (!organization) return;

    form.setValues({
      organization: {
        name: organization.name,
        address: organization.address,
        zip: organization.zip,
        city: organization.city,
        country: organization.country,
        organizationTaxNumber: organization.taxNumber,
        isTaxSubject: organization.taxSubject,

        IBAN: organization.IBAN,
        SWIFT: organization.SWIFT,
        bank: organization.bank,
      },
    });
  }, [organization]);

  const handleSubmit = async (values: Form) => {
    const formValues = {
      ...values,
      organization: {
        ...values.organization,
        IBAN: values.organization.IBAN?.trim().replaceAll(" ", ""),
        SWIFT: values.organization.SWIFT?.trim().replaceAll(" ", ""),
        bank: values.organization.bank?.trim().replaceAll(" ", ""),
      },
    };

    const response = await (async () => {
      if (isExistingOrganization) {
        const res = await updateOrganization({
          body: formValues,
        });
        return res;
      }

      const res = await createOrganization(formValues);
      return res;
    })();

    if (response) {
      notifications.show({
        message: t`Uspešno`,
      });
      navigate("../", {
        relative: "path",
      });
    }
  };

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchOrganization();
        }}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Vzpostavitev davčne blagajne`}
        subPage={{
          title: t`Osnovne informacije`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex flex-col px-4 md:px-8">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 lg:flex-row">
              <div className="flex flex-1 flex-col gap-2">
                <p className="text-sm font-semibold text-gray-400">
                  <Trans>Podatki organizacije</Trans>
                </p>

                <div className="flex flex-col gap-4 lg:flex-row">
                  <div className="flex flex-1 flex-col gap-2">
                    <LimeInput
                      {...form.getInputProps("organization.name")}
                      label={t`Ime`}
                      type="text"
                    />

                    <LimeInput
                      {...form.getInputProps("organization.address")}
                      label={t`Naslov`}
                      type="text"
                    />

                    <LimeInput
                      {...form.getInputProps("organization.zip")}
                      label={t`Poštna številka`}
                      type="text"
                    />

                    <LimeInput
                      {...form.getInputProps("organization.city")}
                      label={t`Mesto`}
                      type="text"
                    />

                    <LimeSelect
                      label={t`Država`}
                      items={countries.map((country) => ({
                        key: country.code,
                        label: country.name,
                        startContent: getUnicodeFlagIcon(country.code),
                      }))}
                      {...form.getInputProps("organization.country")}
                    />

                    <LimeInput
                      {...form.getInputProps(
                        "organization.organizationTaxNumber",
                      )}
                      label={t`Davčna številka`}
                      type="text"
                    />

                    <LimeSwitch
                      {...form.getInputProps("organization.isTaxSubject", {
                        type: "checkbox",
                      })}
                      title={t`Davčni zavezanec`}
                    />
                  </div>
                  <div className="flex flex-1 flex-col gap-2">
                    <LimeInput
                      {...form.getInputProps("organization.IBAN")}
                      label={t`IBAN`}
                    />

                    <LimeInput
                      {...form.getInputProps("organization.SWIFT")}
                      label={t`BIC/SWIFT`}
                    />

                    <LimeInput
                      {...form.getInputProps("organization.bank")}
                      label={t`Banka`}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isExistingOrganization && (
              <>
                <Divider />
                <div>
                  <p className="mb-2">
                    <Trans>Številčenje (FURS)</Trans>
                  </p>

                  <LimeSelect
                    label={t`FURS strategija`}
                    items={[
                      {
                        key: "B",
                        label: t`B - Po napravah`,
                      },
                      {
                        key: "C",
                        label: t`C - Po prostorih`,
                      },
                    ]}
                    {...form.getInputProps("fursStrategy")}
                  />
                </div>
              </>
            )}

            <Divider />

            <LimeAlert
              color="danger"
              message={createOrganizationErrorMessage}
            />
            <LimeAlert
              color="danger"
              message={updateOrganizationErrorMessage}
            />

            <Button
              type="submit"
              isLoading={isCreatingOrganization || isUpdatingOrganization}
              isDisabled={isFetchingOrganization}
              color="primary"
              className="w-full self-end lg:w-fit"
            >
              {isExistingOrganization ? (
                <Trans>Posodobi organizacijo</Trans>
              ) : (
                <Trans>Ustvari organizacijo</Trans>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

type Form = {
  organization: {
    name: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    organizationTaxNumber: string;
    isTaxSubject: boolean;

    IBAN?: string;
    bank?: string;
    SWIFT?: string;
  };
  fursStrategy: "C" | "B";
};
