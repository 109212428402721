import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { Modal, Button, Radio, Flex, Text } from "@mantine/core";
import { Appointment } from "./AppointmentActionsDropdown";

type NoShowModalProps = {
  isOpen: boolean;
  onClose: () => void;
  appointment: Appointment;
  handleUpdateCustomerAppointments: (
    customerAppointments: {
      userAppointmentId: number;
      customerDidAttend: number;
    }[],
  ) => void;
};

export const NoShowModal = ({
  isOpen,
  onClose,
  appointment,
  handleUpdateCustomerAppointments,
}: NoShowModalProps) => {
  const { customers } = appointment;

  const [customerStates, setCustomerStates] = useState(
    customers.map((customer) => ({
      userAppointmentId: customer.userAppointmentId,
      customerDidAttend: customer.customerDidAttend,
    })),
  );

  useEffect(() => {
    setCustomerStates(
      customers.map((customer) => ({
        userAppointmentId: customer.userAppointmentId,
        customerDidAttend: customer.customerDidAttend,
      })),
    );
  }, [customers]);

  const updateCustomerState = (
    userAppointmentId: number,
    customerDidAttend: number,
  ) => {
    setCustomerStates((prevStates) =>
      prevStates.map((state) =>
        state.userAppointmentId === userAppointmentId
          ? { ...state, customerDidAttend }
          : state,
      ),
    );
  };

  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
      <Flex direction="column" gap="lg" py={"lg"} align={"center"}>
        {customers.map((customer) => {
          const customerState = customerStates.find(
            (state) => state.userAppointmentId === customer.userAppointmentId,
          );

          return (
            <Flex key={customer.userAppointmentId} direction="column" gap="md">
              <Text fw={500}>
                {customer.name} {customer.lastName}
              </Text>
              <Radio.Group
                value={String(customerState?.customerDidAttend)}
                onChange={(value) =>
                  updateCustomerState(customer.userAppointmentId, Number(value))
                }
              >
                <Flex direction="row" gap="xl">
                  <Radio label={t`Prisoten`} value="1" />
                  <Radio label={t`Odsoten`} value="-1" />
                </Flex>
              </Radio.Group>
            </Flex>
          );
        })}
      </Flex>

      <Flex justify="flex-end" gap="sm" mt="lg">
        <Button variant="outline" onClick={onClose}>
          {t`Prekliči`}
        </Button>
        <Button
          onClick={() => {
            handleUpdateCustomerAppointments(customerStates);
            onClose();
          }}
        >{t`Shrani`}</Button>
      </Flex>
    </Modal>
  );
};
