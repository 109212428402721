import { t } from "@lingui/core/macro";
import {
  AppointmentCancelReason,
  CustomerAppointmentCancelReason,
} from "@/server-types";

type AppointmentCancelOption = {
  value: AppointmentCancelReason;
  label: string;
};

type UserAppointmentCancelOption = {
  value: CustomerAppointmentCancelReason;
  label: string;
};

export function getAppointmentCancelReasonOptions(): AppointmentCancelOption[] {
  return [
    {
      value: "NoReasonProvided",
      label: t`Razlog ni podan`,
    },
    {
      value: "DuplicateAppointment",
      label: t`Podvojen termin`,
    },
    {
      value: "MadeByMistake",
      label: t`Termin narejen po pomoti`,
    },
    {
      value: "StaffUnavailable",
      label: t`Osebje ni na voljo`,
    },
    {
      value: "CustomerSick",
      label: t`Stranka je zbolela`,
    },
    {
      value: "CustomerNotAvailable",
      label: t`Stranka ni na voljo`,
    },
    {
      value: "PersonalEmergency",
      label: t`Osebna nujnost`,
    },
    {
      value: "Custom",
      label: t`Drugo`,
    },
  ];
}

export function getUserAppointmentCancelReasonOptions(): UserAppointmentCancelOption[] {
  return [
    {
      value: "NoReasonProvided",
      label: t`Razlog ni podan`,
    },
    {
      value: "DuplicateCustomerAppointment",
      label: t`Podvojena stranka`,
    },
    {
      value: "MadeByMistake",
      label: t`Stranka dodana po pomoti`,
    },
    {
      value: "CustomerSick",
      label: t`Stranka je zbolela`,
    },
    {
      value: "CustomerNotAvailable",
      label: t`Stranka ni na voljo`,
    },
    {
      value: "PersonalEmergency",
      label: t`Osebna nujnost`,
    },
    {
      value: "Custom",
      label: t`Drugo`,
    },
  ];
}
