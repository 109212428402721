import { useState } from "react";
import {
  Modal,
  Button,
  Checkbox,
  Flex,
  Select,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { AppointmentCancelReason } from "@/server-types";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getAppointmentCancelReasonOptions } from "@/constants/cancelReasons";

type CancelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  appointmentId: number;
  isRepeating: boolean;
  isLinked: boolean;
};

export const AppointmentCancelModal = ({
  isOpen,
  onClose,
  appointmentId,
  isRepeating = false,
  isLinked = false,
}: CancelModalProps) => {
  useLingui();
  const { hasCancelNotification, deleteAppointment } = useCalendarStore(
    (store) => store,
  );

  const cancelReasonOptions = getAppointmentCancelReasonOptions();

  const [deleteAllRepeating, setDeleteAllRepeating] = useState(false);
  const [deleteAllLinked, setDeleteAllLinked] = useState(false);
  const [sendCancelNotifications, setSendCancelNotifications] = useState(
    hasCancelNotification,
  );
  const [cancelReason, setCancelReason] =
    useState<AppointmentCancelReason>("NoReasonProvided");

  const [customCancelReason, setCustomCancelReason] = useState("");

  const handleConfirm = async () => {
    try {
      await deleteAppointment(
        appointmentId,
        deleteAllRepeating,
        deleteAllLinked,
        sendCancelNotifications,
        cancelReason,
        customCancelReason,
      );
      notifications.show({
        message: t`Termin uspešno izbrisan`,
      });
      onClose();
    } catch (e) {
      notifications.show({
        message: t`Napaka pri brisanju termina`,
        color: "red",
      });
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t`Ali ste prepričani, da želite izbrisati termin?`}
      centered
      withCloseButton={false}
    >
      <Flex direction="column" align="flex-start" gap={10}>
        <Select
          label={t`Razlog za izbris`}
          placeholder={t`Izberite razlog za preklic`}
          data={cancelReasonOptions}
          value={cancelReason}
          onChange={(value) =>
            setCancelReason(value as AppointmentCancelReason)
          }
        />
        {cancelReason === "Custom" && (
          <TextInput
            label={t`Vnesite razlog za odpoved`}
            value={customCancelReason}
            onChange={(e) => setCustomCancelReason(e.currentTarget.value)}
          />
        )}
        {isRepeating && (
          <Checkbox
            checked={deleteAllRepeating}
            onChange={(e) => setDeleteAllRepeating(e.currentTarget.checked)}
            label={t`Izbriši vse nadaljnje ponavljajoče termine?`}
          />
        )}
        {isLinked && (
          <Checkbox
            checked={deleteAllLinked}
            onChange={(e) => setDeleteAllLinked(e.currentTarget.checked)}
            label={t`Izbriši vse povezane termine?`}
          />
        )}
        {hasCancelNotification && (
          <Checkbox
            checked={sendCancelNotifications}
            onChange={(e) =>
              setSendCancelNotifications(e.currentTarget.checked)
            }
            label={t`Pošlji obvestila?`}
          />
        )}
      </Flex>

      <Flex justify="flex-end" gap="md" mt="lg">
        <Button variant="outline" onClick={onClose}>
          <Trans>Prekliči</Trans>
        </Button>
        <Button onClick={handleConfirm} disabled={!cancelReason}>
          <Trans>Izbriši</Trans>
        </Button>
      </Flex>
    </Modal>
  );
};

export default AppointmentCancelModal;
